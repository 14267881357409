import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Pagination, Select, Table, Tooltip, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import CustomButton from 'components/custom-button';
import { useEffect, useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import { formatedDate } from 'utils/common';
import { shortenName } from 'utils/short-name';
import PageTitle from '../../components/page-title';
import ModalCreateEdit from './component/ModalCreateEdit';
import styles from './styles.module.scss';
import { SERVER_FILE_ENDPOINT } from 'constants/envs';
import { renderHeaderTitle } from '../../components/ATableCell';

const LIMIT = 10;

const initFilter = { page: 1, limit: LIMIT, sortType: 'ASC' };

const { Option } = Select;
export interface Filter {
  limit: number;
  page: number;
  sortType: any;
}

const GroupLiveShowManager = () => {
  const [loading, setLoading] = useState(true);
  const [dataResponse, setDataResponse] = useState<any>({
    items: [],
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [statusUpdateData, setStatusUpdateData] = useState(false);
  const [isOpenModalEditAndCreate, setIsOpenModalEditAndCreate] = useState(false);
  const [valueUserSelected, setvalueUserSelected] = useState<any>(null);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    const fetchGroupLiveShow = async () => {
      setLoading(true);
      try {
        const res: any = await AxiosUtils.get(API.groupShow);
        setDataResponse({ items: res?.data });
      } finally {
        setLoading(false);
      }
    };

    fetchGroupLiveShow();
  }, [statusUpdateData]);

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '5%',
      ellipsis: true,
      key: 'id',
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
      render: (value) => (
        <Tooltip title={value}>
          <span>{shortenName(value || '', 70) || ''}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Ngày diễn ra',
      dataIndex: 'date',
      key: 'date',
      render: (value) => <span>{formatedDate(value)}</span>,
    },
    {
      title: 'Action',
      width: 150,
      key: 'action',
      render: (_, record) => (
        <Select
          value="Chọn"
          getPopupContainer={(trigger) => trigger.parentElement}
          dropdownStyle={{ minWidth: 97 }}
        >
          <Option value="action view">
            <div onClick={() => handelOpenCreateEditModal(record)} style={{ textAlign: 'center' }}>
              Sửa
            </div>
          </Option>
        </Select>
      ),
    },
  ];
  const onChangeStatusUpdateData = () => setStatusUpdateData(!statusUpdateData);

  const handelOpenDeleteModal = () => {
    setIsModalDeleteOpen(true);
  };

  const handleOkDeleteModal = async () => {
    setLoading(true);
    try {
      await AxiosUtils._delete(API.groupShowDel, { ids: selectedRowKeys });
      onChangeStatusUpdateData();
      setSelectedRowKeys([]);
      notification.success({
        message: 'Success',
        description: 'Xóa thành công!',
        duration: 4,
      });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    } finally {
      setLoading(false);
      setIsModalDeleteOpen(false);
    }
  };

  const handleCancelDeleteModal = () => {
    setIsModalDeleteOpen(false);
  };

  const handelOpenCreateEditModal = (value: any) => {
    setIsOpenModalEditAndCreate(true);
    setvalueUserSelected(value);
  };

  const handleCancelCreateEditModal = () => {
    setIsOpenModalEditAndCreate(false);
    setvalueUserSelected(null);
  };

  return (
    <div>
      <PageTitle title="Quản lí Group Live Show" />
      <div className={styles.filterContainer}>{/* <Filter onSubmit={onFinish} /> */}</div>
      <div className="mb-10">
        <CustomButton
          onClick={() => handelOpenCreateEditModal(valueUserSelected)}
          bgColor="primary-green"
          text="Thêm Group Live Show"
          icon={<PlusOutlined />}
        />
      </div>
      <div className="custom-table custom-form">
        <Button
          type="primary"
          onClick={handelOpenDeleteModal}
          disabled={!hasSelected}
          loading={loading}
        >
          Xóa
        </Button>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Đã chọn ${selectedRowKeys.length} menu` : ''}
        </span>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          rowKey="id"
          pagination={false}
          dataSource={dataResponse.items}
          scroll={{ x: 600 }}
          loading={loading}
        />
      </div>

      {isOpenModalEditAndCreate && (
        <ModalCreateEdit
          isOpen={isOpenModalEditAndCreate}
          handleCancelCreateEditModal={handleCancelCreateEditModal}
          valueUserSelected={valueUserSelected}
          onChangeStatusUpdateData={onChangeStatusUpdateData}
        />
      )}
      <Modal
        title="Xóa Group"
        open={isModalDeleteOpen}
        onOk={handleOkDeleteModal}
        onCancel={handleCancelDeleteModal}
      >
        <p>Bạn có thực sự muốn xóa {selectedRowKeys.length} group.</p>
      </Modal>
    </div>
  );
};

export default GroupLiveShowManager;
