import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import Logo from 'assets/images/hp-logo.png';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import {
  MailOutlined,
  SettingOutlined,
  UserOutlined,
  DollarOutlined,
  GiftOutlined,
} from '@ant-design/icons';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}
const items: MenuProps['items'] = [
  getItem('Quản lý tin tức', 'sub2', <MailOutlined />, [
    getItem('Bài viết', 'news-management'),
    getItem('Tin tức nổi bật', 'outstanding-management'),
    getItem('Policy HP', 'policy-manager'),
    getItem('Tin nhắn Thông báo', 'job-management'),
    getItem('Gửi Thông báo', 'notification-management'),
  ]),

  { type: 'divider' },

  getItem('Quản lý chung', 'sub4', <SettingOutlined />, [
    getItem('PK Đại chiến', 'pk-management'),
    getItem('Quản lý quốc gia', 'setting-country'),
    getItem('Quản lý Ứng dụng', 'menus-management'),
    getItem('Quản lý Banner', 'banners-management'),
    getItem('Quản lý Liveshow', 'live-management'),
    getItem('HP Store', 'store-management'),
    getItem('Quảng cáo', 'setting-ads'),
    getItem('Quản lý Sound', 'sounds-management'),
    getItem('Nhóm Liveshow', 'show-management'),
  ]),
  getItem('Người dùng', 'sub5', <UserOutlined />, [
    // getItem('Duyệt Idol mới', 'request-idol-management'),
    getItem('Thành viên', 'users-management'),
    getItem('Bảng Xếp Hạng', 'revenue-management'),
    // getItem('Pick Idol', 'pick-idol'),
  ]),

  getItem('Gia Tộc', 'sub6', <DollarOutlined />, [
    getItem('Gia tộc', 'domestic-management'),
    getItem('Thêm thành viên', 'domestic-user'),
  ]),

  getItem('Setting', 'sub7', <SettingOutlined />, [getItem('Setting', 'setting-management')]),
  getItem('Sự kiện', 'sub8', <GiftOutlined />, [
    getItem('Vote', 'vote-management'),
    getItem('Thêm người chơi', 'add-user-vote'),
  ]),
];

const rootSubmenuKeys = ['sub2', 'sub4', 'sub5', 'sub6', 'sub7', 'sub8'];

const MenuHp = () => {
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState(['sub1']);

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
    navigate(`/${e.key}`);
  };

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <div className={styles.menu}>
      <div className={styles.boxLogo}>
        <img height={50} src={Logo} alt="" />
      </div>
      <Link to="/" className={styles.linkToHome} />
      <Menu
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        onClick={onClick}
        theme="dark"
        style={{ width: '100%' }}
        defaultSelectedKeys={['1']}
        mode="inline"
        items={items}
      />
    </div>
  );
};

export default MenuHp;
