import { DatePicker, Form, Input, Modal, notification, Space } from 'antd';
import CustomButton from 'components/custom-button';
import { UPLOAD_TYPE } from 'constants/common';
import { useCallback, useEffect, useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import styles from '../styles.module.scss';
import CustomizedUpload from 'components/customized-upload';
import { SERVER_FILE_ENDPOINT } from 'constants/envs';
import { CHECK_FILE } from 'utils/rule-form';
import { REQUIRED } from '../../../constants/rule-form';
import dayjs from 'dayjs';
import DebounceSelect from '../../../components/debounce-select/select';
import * as _ from 'lodash';

interface DataPost {
  title: string;
  avatar: any;
  description: string;
  ownerId: any;
  assistant: any;
  type?: string;
}

interface Props {
  isOpen: boolean;
  handleCancelCreateEditModal: () => void;
  onChangeStatusUpdateData: () => void;
  valueUserSelected: any;
}

const ModalCreateEdit = ({
  isOpen,
  valueUserSelected,
  handleCancelCreateEditModal,
  onChangeStatusUpdateData,
}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values: DataPost) => {
    values.avatar = values?.avatar?.fileKey;
    values.ownerId = values?.ownerId?.value;
    values.assistant = _.map(values.assistant, 'value').toString() || '';
    values.type = 'DOMESTIC';
    setLoading(true);
    if (valueUserSelected?.id) {
      try {
        const res: any = await AxiosUtils.put(`${API.createGroup}/${valueUserSelected.id}`, {
          ...values,
        });
        if (res?.status === 200) {
          handleCancelCreateEditModal();
          onChangeStatusUpdateData();
          notification.success({
            message: 'Success',
            description: 'Sửa thành công!',
            duration: 4,
          });
        }
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res: any = await AxiosUtils.post(`${API.createGroup}`, { ...values });
        if (res?.status === 201) {
          handleCancelCreateEditModal();
          onChangeStatusUpdateData();
          notification.success({
            message: 'Success',
            description: 'Tạo thành công!',
            duration: 4,
          });
        }
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (valueUserSelected) {
      let ownerId: any = {
        key: valueUserSelected?.owner?.id,
        value: valueUserSelected?.owner?.id,
        label: valueUserSelected?.owner?.username,
      };

      const listAssistant =
        valueUserSelected?.assistant.map((item: any) => {
          return {
            key: item.id,
            value: item.id,
            label: item.username,
          };
        }) || [];

      form.setFieldsValue({
        title: valueUserSelected?.title || '',
        description: valueUserSelected?.description || '',
        ownerId: ownerId,
        assistant: listAssistant,
        avatar: valueUserSelected?.avatar
          ? {
              fileKey: valueUserSelected?.avatar,
              fileUrl: `${SERVER_FILE_ENDPOINT}/${valueUserSelected.avatar}`,
            }
          : null,
      });
    }
  }, [form, valueUserSelected]);

  const filterOptions = useCallback(async (search: string) => {
    const response: any = await AxiosUtils.get(`${API.searchUser}?query=${search}`);
    return response.data.map((item: any) => ({
      id: item.id,
      label: item.username,
      value: item.id,
    }));
  }, []);

  return (
    <>
      <Modal
        forceRender
        open={isOpen}
        title={valueUserSelected?.id ? 'Sửa thông tin gia tộc' : 'Thêm thông tin gia tộc'}
        onCancel={handleCancelCreateEditModal}
        width={800}
        getContainer={false}
        footer={false}
      >
        <Form
          name="form"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <label className={styles.labelInput} htmlFor="title">
            Tên gia tộc
          </label>
          <Form.Item
            name="title"
            rules={[
              {
                max: 200,
                message: 'Không được quá 200 kí tự',
              },
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="Nhập tên gia tộc" size="large" />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="description">
            Mô tả gia tộc
          </label>
          <Form.Item
            name="description"
            rules={[
              {
                max: 300,
                message: 'Không được quá 200 kí tự',
              },
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="Nhập tên gia tộc" size="large" />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="ownerId">
            Chủ gia tộc
          </label>

          <Form.Item name="ownerId" wrapperCol={{ span: 24 }} rules={[REQUIRED]}>
            <DebounceSelect placeholder="Chọn chủ gia tộc" fetchOptions={filterOptions} />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="assistant">
            Trợ lý gia tộc
          </label>

          <Form.Item name="assistant" wrapperCol={{ span: 24 }} rules={[REQUIRED]}>
            <DebounceSelect
              mode={'multiple'}
              placeholder="Chọn người support"
              fetchOptions={filterOptions}
            />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="avatar">
            Ảnh
          </label>
          <Form.Item name="avatar" wrapperCol={{ span: 24 }} rules={[CHECK_FILE]}>
            <CustomizedUpload type={UPLOAD_TYPE.IMAGE} maxFileSize={5} />
          </Form.Item>

          <div className="d-flex justify-center">
            <Space size="large">
              <CustomButton
                bgColor="secondary-green"
                text="Submit"
                htmlType="submit"
                loading={loading}
              />
              <CustomButton
                text="Hủy Bỏ"
                bgColor="primary-grey"
                htmlType="button"
                onClick={handleCancelCreateEditModal}
              />
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCreateEdit;
