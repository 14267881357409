import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Pagination, Select, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import CustomButton from 'components/custom-button';
import { useEffect, useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import { shortenName } from 'utils/short-name';
import PageTitle from '../../components/page-title';
import ModalCreateEdit from './component/ModalCreateEdit';
import styles from './styles.module.scss';
import Filter, { getMonthCurrent } from './component/filter';
import moment from 'moment';
import lodash from 'lodash';

const LIMIT = 10;

const initFilter = { page: 1, limit: LIMIT, date: `${moment().year()}-${getMonthCurrent()}` };

const { Option } = Select;
export interface UserFilter {
  limit: number;
  page: number;
  type?: string;
  date?: string;
}

const RevenueManager = () => {
  const [loading, setLoading] = useState(true);
  const [dataResponse, setDataResponse] = useState<any>({
    items: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState(initFilter as UserFilter);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [statusUpdateData, setStatusUpdateData] = useState(false);
  const [isOpenModalEditAndCreate, setIsOpenModalEditAndCreate] = useState(false);
  const [valueUserSelected, setvalueUserSelected] = useState<any>(null);

  const onFinish = (value: Partial<UserFilter>, isClear = false) => {
    if (isClear) {
      const filterClear = { ...filter, ...value, page: 1 };
      delete filterClear.type;
      setFilter({ ...filterClear, page: 1 });
      return;
    }
    setFilter((preState) => ({ ...preState, ...value, page: 1 }));
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const res: any = await AxiosUtils.get(API.revenues, {
          ...filter,
        });
        setDataResponse({ items: res?.data?.data, totalCount: res?.data?.metadata?.total });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [filter, statusUpdateData]);

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '5%',
      ellipsis: true,
      key: 'id',
    },
    {
      title: 'Tiktok ID',
      key: 'user',
      render: (_, record) => (
        <Tooltip
          title={lodash.get(
            record,
            'user.username',
            `${record.tiktokIdol ? record.tiktokIdol : ''}`
          )}
        >
          <span>
            {shortenName(
              lodash.get(
                record,
                'user.username',
                `${record.tiktokIdol ? record.tiktokIdol : ''}`
              ) || '',
              70
            ) || ''}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Gia tộc',
      key: 'group',
      render: (_, record) => (
        <span>
          {shortenName(
            lodash.get(
              record,
              'group.title',
              `${record.tiktokAgency ? record.tiktokAgency : ''}`
            ) || '',
            70
          ) || ''}
        </span>
      ),
    },
    {
      title: 'Số kim cương',
      dataIndex: 'totalDiamond',
      key: 'totalDiamond',
      render: (value) => <span>{Number(value).toLocaleString()}</span>,
    },
    {
      title: 'Tháng live',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Tổng thời gian livestream',
      key: 'total',
      render: (_, record) => (
        <div>
          {record?.hour + ' giờ '}
          {record?.minute > 0 && record?.minute + ' phút'}
        </div>
      ),
    },
    {
      title: 'Trung bình live/ngày',
      key: 'average',
      render: (_, record) => (
        <span>{Number(Number((record?.hour + record?.minute / 60) / 30).toFixed(2))} giờ</span>
      ),
    },
    // {
    //   title: 'Ngày tạo',
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   render: (value) => <span>{formatedDate(value)}</span>,
    // },
    {
      title: 'Action',
      width: 150,
      key: 'action',
      render: (_, record) => (
        <Select
          value="Chọn"
          getPopupContainer={(trigger) => trigger.parentElement}
          dropdownStyle={{ minWidth: 97 }}
        >
          <Option value="action view">
            <div onClick={() => handelOpenCreateEditModal(record)} style={{ textAlign: 'center' }}>
              Sửa
            </div>
          </Option>
        </Select>
      ),
    },
  ];
  const onChangeStatusUpdateData = () => setStatusUpdateData(!statusUpdateData);

  const handleChangePage = (page: number) => {
    setFilter((preState: any) => ({ ...preState, page }));
  };

  const handelOpenDeleteModal = () => {
    setIsModalDeleteOpen(true);
  };

  const handleOkDeleteModal = async () => {
    setLoading(true);
    try {
      await AxiosUtils._delete(API.revenueDelete, { ids: selectedRowKeys });
      onChangeStatusUpdateData();
      setSelectedRowKeys([]);
      notification.success({
        message: 'Success',
        description: 'Xóa thành công!',
        duration: 4,
      });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    } finally {
      setLoading(false);
      setIsModalDeleteOpen(false);
    }
  };

  const handleCancelDeleteModal = () => {
    setIsModalDeleteOpen(false);
  };

  const handelOpenCreateEditModal = (value: any) => {
    console.log(value, 'value selected');
    setIsOpenModalEditAndCreate(true);
    setvalueUserSelected(value);
  };

  const handleCancelCreateEditModal = () => {
    setIsOpenModalEditAndCreate(false);
    setvalueUserSelected(null);
  };

  return (
    <div>
      <PageTitle title="Quản lí doanh thu" />
      <div className={styles.filterContainer}>
        <Filter onSubmit={onFinish} />
      </div>
      <div className="mb-10">
        <CustomButton
          onClick={() => handelOpenCreateEditModal(valueUserSelected)}
          bgColor="primary-green"
          text="Thêm doanh thu"
          icon={<PlusOutlined />}
        />
      </div>
      <div className="custom-table custom-form">
        <Button
          type="primary"
          onClick={handelOpenDeleteModal}
          disabled={!hasSelected}
          loading={loading}
        >
          Xóa
        </Button>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Đã chọn ${selectedRowKeys.length} items` : ''}
        </span>

        <Table
          rowSelection={rowSelection}
          columns={columns}
          rowKey="id"
          pagination={false}
          dataSource={dataResponse.items}
          scroll={{ x: 600 }}
          loading={loading}
        />
        {dataResponse.totalCount > LIMIT && (
          <Pagination
            showSizeChanger={false}
            defaultCurrent={LIMIT}
            current={filter.page}
            onChange={handleChangePage}
            showLessItems
            total={dataResponse.totalCount}
            pageSize={LIMIT}
          />
        )}
      </div>

      {isOpenModalEditAndCreate && (
        <ModalCreateEdit
          isOpen={isOpenModalEditAndCreate}
          handleCancelCreateEditModal={handleCancelCreateEditModal}
          valueUserSelected={valueUserSelected}
          onChangeStatusUpdateData={onChangeStatusUpdateData}
        />
      )}
      <Modal
        title="Xóa User"
        open={isModalDeleteOpen}
        onOk={handleOkDeleteModal}
        onCancel={handleCancelDeleteModal}
      >
        <p>Bạn có thực sự muốn xóa {selectedRowKeys.length} tin tức.</p>
      </Modal>
    </div>
  );
};

export default RevenueManager;
