import { Empty, Select, Spin } from 'antd';
import type { SelectProps } from 'antd/es/select';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';

type ValueType = { key?: string; label: React.ReactNode; value: any };

type Props = {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
} & Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'>;

const DebounceSelect: React.FC<Props> = (props) => {
  const { fetchOptions, debounceTimeout = 500, disabled, ...rest } = props;
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      if (!value) {
        setOptions([]);
        return;
      }
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const onScroll = (e: any) => {
    const target: any = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      // load more goes here
    }
  };

  return (
    <Select
      size={'large'}
      onPopupScroll={onScroll}
      showSearch
      labelInValue
      disabled={disabled}
      placeholder
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={
        fetching ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      }
      {...rest}
      options={options}
    />
  );
};

export default DebounceSelect;
