import React from 'react';
import PageTitle from '../../components/page-title';

const NotFound = () => {
  return (
    <div>
      <PageTitle title='Page Not Found' />
    </div>
  );
};

export default NotFound;
