import { Form, Input, Modal, Space, notification } from 'antd';
import CustomButton from 'components/custom-button';
import { REQUIRED } from 'constants/rule-form';
import { useEffect, useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import styles from '../styles.module.scss';
import { CHECK_FILE } from 'utils/rule-form';
import CustomizedUpload from 'components/customized-upload';
import { UPLOAD_TYPE } from 'constants/common';
import { SERVER_FILE_ENDPOINT } from 'constants/envs';
interface DataPost {
  country: string;
  img: any;
}

interface Props {
  isOpen: boolean;
  handleCancelCreateEditModal: () => void;
  onChangeStatusUpdateData: () => void;
  valueUserSelected: any;
}

const ModalCreateEdit = ({
  isOpen,
  valueUserSelected,
  handleCancelCreateEditModal,
  onChangeStatusUpdateData,
}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: DataPost) => {
    values.img = values?.img?.fileKey;
    setLoading(true);
    if (valueUserSelected?.id) {
      try {
        const res: any = await AxiosUtils.patch(
          `${API.updateCountry}/${valueUserSelected?.id}`,
          values
        );
        if (res?.status === 200) {
          handleCancelCreateEditModal();
          onChangeStatusUpdateData();
          notification.success({
            message: 'Success',
            description: 'Sửa thành công!',
            duration: 4,
          });
        }
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res: any = await AxiosUtils.post(API.updateCountry, values);
        if (res?.status === 201) {
          handleCancelCreateEditModal();
          onChangeStatusUpdateData();
          notification.success({
            message: 'Success',
            description: 'Tạo thành công!',
            duration: 4,
          });
        }
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      country: valueUserSelected?.country || '',
      img: valueUserSelected?.img
        ? {
            fileKey: valueUserSelected?.img,
            fileUrl: `${SERVER_FILE_ENDPOINT}/${valueUserSelected.img}`,
          }
        : null,
    });
  }, [form, valueUserSelected]);

  return (
    <>
      <Modal
        forceRender
        open={isOpen}
        title={valueUserSelected?.id ? 'Sửa Country' : 'Thêm Country'}
        onCancel={handleCancelCreateEditModal}
        width={800}
        getContainer={false}
        footer={false}
      >
        <Form
          name="form"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <label className={styles.labelInput} htmlFor="country">
            Country
          </label>
          <Form.Item
            name="country"
            rules={[
              REQUIRED,
              {
                max: 100,
                message: 'Không được quá 100 kí tự',
              },
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="Nhập country" size="large" />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="img">
            Ảnh
          </label>
          <Form.Item name="img" wrapperCol={{ span: 24 }} rules={[CHECK_FILE]}>
            <CustomizedUpload type={UPLOAD_TYPE.IMAGE} maxFileSize={5} />
          </Form.Item>

          <div className="d-flex justify-center">
            <Space size="large">
              <CustomButton
                bgColor="secondary-green"
                text="Submit"
                htmlType="submit"
                loading={loading}
              />
              <CustomButton
                text="Hủy Bỏ"
                bgColor="primary-grey"
                htmlType="button"
                onClick={handleCancelCreateEditModal}
              />
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCreateEdit;
