import React from 'react';
import {
  Form,
  Input,
  Modal,
  Select,
  Space,
  notification,
  Col,
  Checkbox,
  Row,
  DatePicker,
  InputNumber,
} from 'antd';
import CustomButton from 'components/custom-button';
import { REQUIRED } from 'constants/rule-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import styles from '../styles.module.scss';
import { REGEX_USERNAME, ROLE_USER } from 'constants/common';
import DebounceSelect from 'components/debounce-select/select';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import moment from 'moment';
import { formatData } from '../../../utils/common';
import dayjs from 'dayjs';
const { Option } = Select;

interface Props {
  isOpen: boolean;
  handleCancelCreateEditModal: () => void;
  onChangeStatusUpdateData: () => void;
  valueUserSelected: any;
}

const ModalCreateEdit = ({
  isOpen,
  valueUserSelected,
  handleCancelCreateEditModal,
  onChangeStatusUpdateData,
}: Props) => {
  const [form] = Form.useForm();
  const typeData = Form.useWatch('type', form);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<any>(ROLE_USER.CREATOR);
  const [checkIdol, setCheckIdol] = useState(false);
  const [checkAgency, setCheckAgency] = useState(false);

  const Months = useMemo(() => {
    return Array(12)
      .fill(null)
      .map((item, index) => {
        return { value: index + 1 };
      });
  }, []);

  const onFinish = async (values: any) => {
    setLoading(true);

    if (values.groupId) {
      values.groupId = values?.groupId?.value;
    }

    if (values.userId) {
      values.userId = values?.userId?.value;
    }
    let dataPost = {};

    if (type === ROLE_USER.CREATOR) {
      dataPost = {
        ...values,
        day: Number(values.day),
        hour: Number(values.hour),
        date: values.date.format('YYYY-MM'),
        totalDiamond: Number(values.totalDiamond),
      };
    } else {
      dataPost = {
        ...values,
        totalDiamond: Number(values.totalDiamond),
      };
    }

    if (valueUserSelected?.id) {
      try {
        const res: any = await AxiosUtils.put(
          `${API.revenues}/${valueUserSelected?.id}`,
          formatData(dataPost)
        );
        if (res?.status === 200) {
          handleCancelCreateEditModal();
          onChangeStatusUpdateData();
          notification.success({
            message: 'Success',
            description: 'Tạo thành công!',
            duration: 4,
          });
        }
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await AxiosUtils.post(API.revenues, dataPost);
        handleCancelCreateEditModal();
        onChangeStatusUpdateData();
        notification.success({
          message: 'Success',
          description: 'Tạo thành công!',
          duration: 4,
        });
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (valueUserSelected?.type) {
      setType(valueUserSelected?.type);
    }
    form.setFieldsValue({
      type: valueUserSelected?.type || type,
    });
  }, []);

  useEffect(() => {
    if (valueUserSelected?.type === ROLE_USER.CREATOR) {
      let idolId: any = {};
      if (valueUserSelected.tiktokIdol) {
        setCheckIdol(true);
      }

      if (valueUserSelected?.user) {
        idolId.key = valueUserSelected?.user?.id;
        idolId.value = valueUserSelected?.user?.id;
        idolId.label = valueUserSelected?.user?.username;
      }

      form.setFieldsValue({
        type: valueUserSelected?.type,
        date: dayjs(valueUserSelected?.date),
        userId: idolId,
        tiktokIdol: valueUserSelected?.tiktokIdol || '',
        day: valueUserSelected?.day,
        hour: valueUserSelected?.hour,
        totalDiamond: valueUserSelected?.totalDiamond,
      });
    }

    if (valueUserSelected?.type === ROLE_USER.AGENCY) {
      let groupId: any = {};

      if (valueUserSelected?.group) {
        groupId.key = valueUserSelected?.group?.id;
        groupId.value = valueUserSelected?.group?.id;
        groupId.label = valueUserSelected?.group?.title;
      }

      form.setFieldsValue({
        type: valueUserSelected?.type,
        totalDiamond: valueUserSelected?.totalDiamond,
        groupId: groupId,
      });
    }
  }, [form, valueUserSelected]);

  const handleChange = (value: string) => {
    setType(value);
  };

  const filterAgencyOptions = useCallback(async (search: string) => {
    const response: any = await AxiosUtils.get(`${API.searchGroup}?query=${search}`);
    return response.data.map((item: any) => ({
      id: item.id,
      label: item.title,
      value: item.id,
    }));
  }, []);

  const filterUserOptions = useCallback(async (search: string) => {
    const response: any = await AxiosUtils.get(`${API.searchUser}?query=${search}`);
    return response.data.map((item: any) => ({
      id: item.id,
      label: item.username,
      value: item.id,
    }));
  }, []);

  useEffect(() => {
    // form.setFieldsValue({
    //   tiktokIdol: '',
    //   userId: undefined,
    // });
  }, [form, checkIdol]);

  useEffect(() => {
    // form.setFieldsValue({
    //   tiktokAgency: '',
    //   agencyId: undefined,
    // });
  }, [form, checkAgency]);

  return (
    <>
      <Modal
        forceRender
        open={isOpen}
        title={valueUserSelected?.id ? 'Sửa doanh thu' : 'Thêm doanh thu'}
        onCancel={handleCancelCreateEditModal}
        width={800}
        getContainer={false}
        footer={false}
      >
        <Form
          name="form"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <label className={styles.labelInput} htmlFor="type">
            Loại doanh thu
          </label>
          <Form.Item rules={[REQUIRED]} name="type" wrapperCol={{ span: 24 }}>
            <Select
              defaultValue={ROLE_USER.CREATOR}
              placeholder="Chọn type"
              size="large"
              onChange={handleChange}
            >
              <Option value={ROLE_USER.AGENCY}>Agency</Option>
              <Option value={ROLE_USER.CREATOR}>Creator</Option>
            </Select>
          </Form.Item>
          {typeData === ROLE_USER.AGENCY ? (
            <React.Fragment>
              <label className={styles.labelInput} htmlFor="groupId">
                Tìm Gia Tộc
              </label>
              <Row gutter={[16, 0]} className="d-flex">
                <Col span={16}>
                  <Form.Item name="groupId" rules={[REQUIRED]} wrapperCol={{ span: 24 }}>
                    <DebounceSelect placeholder="Chọn Gia tộc" fetchOptions={filterAgencyOptions} />
                  </Form.Item>
                </Col>
              </Row>
            </React.Fragment>
          ) : null}

          {typeData === ROLE_USER.CREATOR && (
            <>
              <label className={styles.labelInput} htmlFor="userId">
                Tiktok Id Idol
              </label>

              <Row gutter={[16, 0]} className="d-flex justify-center">
                <Col span={15}>
                  {checkIdol ? (
                    <Form.Item
                      name="tiktokIdol"
                      rules={[
                        REQUIRED,
                        {
                          max: 100,
                          message: 'Không được quá 100 kí tự',
                        },
                        {
                          pattern: new RegExp(REGEX_USERNAME),
                          message: 'Nhập đúng format user',
                        },
                      ]}
                      wrapperCol={{ span: 24 }}
                      validateFirst={true}
                    >
                      <Input placeholder="Nhập Tiktok ID Idol" size="large" />
                    </Form.Item>
                  ) : (
                    <Form.Item name="userId" rules={[REQUIRED]} wrapperCol={{ span: 24 }}>
                      <DebounceSelect placeholder="Chọn Idol" fetchOptions={filterUserOptions} />
                    </Form.Item>
                  )}
                </Col>
                <Col span={9}>
                  <Checkbox
                    checked={checkIdol}
                    onChange={(e: CheckboxChangeEvent) => {
                      e.target.checked
                        ? form.setFieldsValue({
                            userId: undefined,
                          })
                        : form.setFieldsValue({
                            tiktokIdol: '',
                          });
                      setCheckIdol(e.target.checked);
                    }}
                  >
                    Không tìm thấy Idol
                  </Checkbox>
                </Col>
              </Row>

              <label className={styles.labelInput} htmlFor="month">
                Nhập tháng livestream của Idol
              </label>
              <Form.Item rules={[REQUIRED]} name="date" wrapperCol={{ span: 24 }}>
                <DatePicker size={'large'} picker="month" />
              </Form.Item>

              <label className={styles.labelInput} htmlFor="day">
                Số ngày live
              </label>
              <Form.Item
                name="day"
                rules={[
                  REQUIRED,
                  {
                    pattern: /^(?:\d*)$/,
                    message: 'Hãy nhập một con số',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (Number(getFieldValue('day')) < 1 || Number(getFieldValue('day')) > 31) {
                        return Promise.reject(
                          new Error('Giá trị phải lớn hơn 0 và nhỏ hơn hoặc bằng 31')
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                validateFirst={true}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Nhập sô ngày live" size="large" />
              </Form.Item>
              <label className={styles.labelInput} htmlFor="country">
                Số giờ live
              </label>
              <Form.Item
                name="hour"
                rules={[
                  REQUIRED,
                  {
                    pattern: /^(?:\d*)$/,
                    message: 'Hãy nhập một con số',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        Number(getFieldValue('hour')) < 1 ||
                        Number(getFieldValue('hour')) > 755
                      ) {
                        return Promise.reject(new Error('Giá trị phải lớn hơn 0 và nhỏ hơn  755'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                wrapperCol={{ span: 24 }}
                validateFirst={true}
              >
                <Input placeholder="Nhập số giờ live" size="large" />
              </Form.Item>
            </>
          )}

          <>
            <label className={styles.labelInput} htmlFor="totalDiamond">
              Tổng kim cương
            </label>
            <Form.Item
              name="totalDiamond"
              rules={[
                REQUIRED,
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      Number(getFieldValue('totalDiamond')) < 1 ||
                      Number(getFieldValue('totalDiamond')) > 999999999
                    ) {
                      return Promise.reject(
                        new Error('Giá trị phải lớn hơn 0 và nhỏ hơn hoặc bằng 999999999')
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              wrapperCol={{ span: 24 }}
            >
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                placeholder="Nhập kim cương"
                size="large"
                style={{ width: 300 }}
              />
            </Form.Item>
          </>

          <div className="d-flex justify-center">
            <Space size="large">
              <CustomButton
                bgColor="secondary-green"
                text="Submit"
                htmlType="submit"
                loading={loading}
              />
              <CustomButton
                text="Hủy Bỏ"
                bgColor="primary-grey"
                htmlType="button"
                onClick={handleCancelCreateEditModal}
              />
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCreateEdit;
