import { Button, ButtonProps } from 'antd';
import React from 'react';

interface IButtonProps extends ButtonProps {
  width?: number;
  height?: number;
  bgColor?: ButtonType;
  color?: string;
  text?: JSX.Element | string;
  borderRadius?: number;
  disabled?: boolean;
  fontWeight?: string;
}

export type ButtonType =
  | 'primary-green'
  | 'secondary-green'
  | 'third-green'
  | 'primary-orange'
  | 'primary-grey'
  | 'primary-red'
  | 'primary-outline-red'
  | '';

const getButtonConfig = (
  bgColor: ButtonType = '',
  disabled: boolean | undefined
): { background: string; borderColor: string; color: string } => {
  const config = {
    background: '',
    borderColor: '',
    color: 'white',
    fontWeight: '',
  };

  if (disabled) {
    config.background = '#d9d9d9';
    config.borderColor = '#d9d9d9';
    return config;
  }

  switch (bgColor) {
    case 'primary-green':
      config.background = '#18C3AF';
      config.borderColor = '#18C3AF';
      break;
    case 'secondary-green':
      config.background = '#4AC86D';
      config.borderColor = '#4AC86D';
      break;
    case 'third-green':
      config.background = '#fffff';
      config.borderColor = '#18C3AF';
      break;
    case 'primary-orange':
      config.background = '#ef972b';
      config.borderColor = '#ef972b';
      break;
    case 'primary-grey':
      config.background = '#F3F4F4';
      config.borderColor = '#F3F4F4';
      config.color = '#121212';
      break;
    case 'primary-red':
      config.background = '#FF7070';
      config.borderColor = '#FF7070';
      break;
    case 'primary-outline-red':
      config.background = 'white';
      config.borderColor = '#FF7070';
      config.color = '#FF7070';
      break;
    default:
      config.background = '#da3535';
      config.borderColor = '#da3535';
  }

  return config;
};

function CustomButton({
  width,
  height = 40,
  bgColor,
  text,
  color,
  borderRadius = 6,
  disabled,
  fontWeight = '700',
  style,
  ...rest
}: IButtonProps) {
  const { background, borderColor, color: defaultConfigColor } = getButtonConfig(bgColor, disabled);
  return (
    <Button
      {...rest}
      style={{
        background,
        borderColor,
        width,
        height,
        borderRadius,
        color: color ?? defaultConfigColor,
        fontWeight,
        ...style,
      }}
      disabled={disabled}
    >
      {text}
    </Button>
  );
}

export default CustomButton;
