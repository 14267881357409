import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import React from 'react';
import CustomButton from 'components/custom-button';
import moment from 'moment';
import { ROLE_USER } from '../../../constants/common';
import { formatData } from '../../../utils/common';

const { Option } = Select;
type FilterProps = {
  onSubmit: (value: any) => void;
};
const { RangePicker } = DatePicker;

const initialValues = {
  username: '',
  email: '',
  roles: '',
  datePicker: [],
};

const Filter: React.FC<any> = (props) => {
  const [form] = Form.useForm();
  const { onSubmit } = props;

  const onClearFilter = () => {
    form.resetFields();
    onSubmit({ ...form.getFieldsValue(), startDate: '', endDate: '', roles: '' });
  };

  const onFormSubmit = (value: typeof initialValues) => {
    const { datePicker, ...rest } = value;
    let startDate = '';
    let endDate = '';

    if (datePicker) {
      const [start, end] = datePicker as moment.Moment[];

      startDate = start?.format('YYYY-MM-DD');
      endDate = end?.format('YYYY-MM-DD');
    }

    onSubmit({ ...rest, startDate, endDate });
  };

  return (
    <Form form={form} name="register" onFinish={onFormSubmit} initialValues={initialValues}>
      <Row gutter={[24, 0]} className="custom-form">
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="username" label="Tiktok ID">
            <Input placeholder="Username" />
          </Form.Item>
        </Col>
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="roles" label="Role" wrapperCol={{ span: 24 }}>
            <Select placeholder="Chọn role" size="large">
              <Option value={ROLE_USER.USER}>User</Option>
              <Option value={ROLE_USER.MOD}>Mod</Option>
              <Option value={ROLE_USER.CREATOR}>Creator</Option>
              <Option value={ROLE_USER.ADMIN}>Admin</Option>
              <Option value={ROLE_USER.AGENCY}>Agency</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="email" label="Email">
            <Input placeholder="Email" />
          </Form.Item>
        </Col>
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item label="Tìm kiếm theo ngày" name="datePicker">
            <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
          </Form.Item>
        </Col>
        <Col style={{ paddingTop: 25 }}>
          <Form.Item className="text-right mt-10">
            <CustomButton
              bgColor="secondary-green"
              htmlType="submit"
              text="Tìm kiếm"
              icon={<SearchOutlined />}
            />
            <CustomButton
              text="Clear"
              bgColor="primary-grey"
              icon={<CloseOutlined />}
              className="ml-5"
              onClick={onClearFilter}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
