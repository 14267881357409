import AxiosUtils from "utils/axios-utils"
import { API } from "./api-route"
import { notification } from "antd";
import { SERVER_FILE_ENDPOINT } from "constants/envs";

export const uploadFileFormData = async (file: any) => {
    try {
        const postFormData = new FormData();
        postFormData.append('attachment', file);
        const res: any = await AxiosUtils.post(API.uploadFile, postFormData);
        return res.data
    } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    }
}