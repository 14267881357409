const UPLOAD_TYPE = {
  IMAGE: 'image',
  AUDIO: 'audio',
  VIDEO: 'video',
};

const REQUIRED_MESSAGE = 'Trường này là bắt buộc.';

const FILE_FORMAT = {
  [UPLOAD_TYPE.IMAGE]: ['png', 'jpeg', 'jpg', 'svg'],
  [UPLOAD_TYPE.AUDIO]: [
    'mp3',
    'wav',
    'ogg',
    'mp4',
    'adts',
    '3gp',
    'mov',
    'flac',
    'm4a',
    'webm',
    'mpeg',
  ],
  [UPLOAD_TYPE.VIDEO]: ['mp4', 'webm', '3gp', 'ogg', 'mpeg'],
};

export enum ROLE_USER {
  USER = 'USER',
  ADMIN = 'ADMIN',
  MOD = 'MOD',
  AGENCY = 'AGENCY',
  CREATOR = 'CREATOR',
  STUDIO_CREATOR = 'STUDIO_CREATOR'
}

export const SORT_DIRECTION = {
  UP: 'ASC',
  DOWN: 'DESC',
};

export enum TYPE_NEW {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum TYPE_SOUND {
  MUSIC = 'MUSIC',
  SOUND_EFFECT = 'SOUND_EFFECT',
  PAY_THE_BET = 'PAY_THE_BET',
  SYNTHETIC = 'SYNTHETIC',
}

export enum TYPE_MENU {
  ALL = 'ALL',
  AGENCY = 'AGENCY',
  CREATOR = 'CREATOR',
  HP_COMPANY = 'HP_COMPANY',
}
export enum TYPE_LIVE_SHOW {
  ALL = 'ALL',
  CAROUSEL = 'CAROUSEL',
}

export enum TYPE_BANNER {
  HOME = 'HOME',
  LIVE_SHOW = 'LIVE_SHOW',
  PK = 'PK',
}

export enum TYPE_JOB {
  NOW = 'NOW',
  NO = 'NO',
  CALENDAR = 'CALENDAR',
}

export const MONTHS = [
  { value: 1, month: '1' },
  { value: 2, month: '1' },
  { value: 3, month: '1' },
  { value: 4, month: '1' },
  { value: 1, month: '1' },
  { value: 1, month: '1' },
  { value: 1, month: '1' },
  { value: 1, month: '1' },
  { value: 1, month: '1' },
  { value: 1, month: '1' },
];

export const REGEX_USERNAME = /^[a-zA-Z0-9_.]+$/;

export { UPLOAD_TYPE, REQUIRED_MESSAGE, FILE_FORMAT };
