import { Modal, Pagination, Select, Table, Tooltip, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import DefaultImage from 'assets/images/default-img.png';
import { SERVER_FILE_ENDPOINT } from 'constants/envs';
import { useEffect, useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import { formatedDate } from 'utils/common';
import { shortenName } from 'utils/short-name';
import PageTitle from '../../components/page-title';
import styles from './styles.module.scss';

const LIMIT = 10;

const initFilter = { page: 1, limit: LIMIT };

const { Option } = Select;
export interface UserFilter {
  limit: number;
  page: number;
  username?: string;
  email?: string;
  startDate?: string;
  endDate?: string;
}

const RequestIdolManager = () => {
  const [loading, setLoading] = useState(true);
  const [dataResponse, setDataResponse] = useState<any>({
    items: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState(initFilter as UserFilter);
  const [statusUpdateData, setStatusUpdateData] = useState(false);
  const [valueUserSelected, setValueUserSelected] = useState<any>(null);
  const [isModalUpdateRequestIdol, setIsModalUpdateRequestIdol] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const res: any = await AxiosUtils.get(API.requestIdol, {
          ...filter,
        });
        setDataResponse({ items: res?.data?.data, totalCount: res?.data?.metadata?.total });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [filter, statusUpdateData]);

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '5%',
      ellipsis: true,
      key: 'id',
    },
    {
      title: 'Tên',
      dataIndex: 'username',
      key: 'username',
      render: (value) => (
        <Tooltip title={value}>
          <span>{shortenName(value || '', 70) || ''}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Ảnh',
      dataIndex: 'sender',
      key: 'anh',
      render: (sender) => (
        <img
          width={136}
          height={72}
          style={{ objectFit: 'cover' }}
          src={
            sender?.profile?.avatar
              ? `${SERVER_FILE_ENDPOINT}/${sender?.profile?.avatar}`
              : DefaultImage
          }
          alt=""
        />
      ),
    },

    {
      title: 'Role',
      dataIndex: 'sender',
      key: 'Role',
      render: (sender) => <span>{sender?.roles}</span>,
    },

    {
      title: 'Email',
      dataIndex: 'sender',
      key: 'email',
      render: (sender) => <span>{sender?.email}</span>,
    },

    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
    },

    {
      title: 'Thời gian tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => <span>{formatedDate(value)}</span>,
    },
    {
      title: 'Action',
      width: 150,
      key: 'action',
      render: (_, record) => (
        <Select
          value="Chọn"
          getPopupContainer={(trigger) => trigger.parentElement}
          dropdownStyle={{ minWidth: 97 }}
        >
          <Option value="action view">
            <div onClick={() => handleAction(record, 'Accept')} style={{ textAlign: 'center' }}>
              Accept{' '}
            </div>
          </Option>
          <Option value="action view">
            <div onClick={() => handleAction(record, 'Reject')} style={{ textAlign: 'center' }}>
              Reject
            </div>
          </Option>
        </Select>
      ),
    },
  ];
  const onChangeStatusUpdateData = () => setStatusUpdateData(!statusUpdateData);

  const handleChangePage = (page: number) => {
    setFilter((preState: any) => ({ ...preState, page }));
  };

  const handleUpdateRequestIdol = async () => {
    setLoading(true);
    if (valueUserSelected?.action === 'Accept') {
      try {
        await AxiosUtils.patch(`${API.updateRequestIdol}/${valueUserSelected?.id}/accept`);
        setIsModalUpdateRequestIdol(false);
        onChangeStatusUpdateData();
        notification.success({
          message: 'Success',
          description: 'Accept Idol thành công!',
          duration: 4,
        });
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await AxiosUtils.patch(`${API.updateRequestIdol}/${valueUserSelected?.id}/reject`);
        setIsModalUpdateRequestIdol(false);
        onChangeStatusUpdateData();
        notification.success({
          message: 'Success',
          description: 'Reject Idol thành công!',
          duration: 4,
        });
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleAction = (value: any, action: 'Accept' | 'Reject') => {
    setIsModalUpdateRequestIdol(true);
    setValueUserSelected({ ...value, action: action });
  };

  const handleCancelRequestIdol = (value: any) => {
    setIsModalUpdateRequestIdol(false);
    setValueUserSelected(null);
  };

  return (
    <div>
      <PageTitle title="Quản lí Request Idol" />
      <div className={styles.filterContainer}>{/* <Filter onSubmit={onFinish} /> */}</div>

      <div className="custom-table custom-form">
        <Table
          columns={columns}
          rowKey="id"
          pagination={false}
          dataSource={dataResponse.items}
          scroll={{ x: 600 }}
          loading={loading}
        />
        {dataResponse.totalCount > LIMIT && (
          <Pagination
            showSizeChanger={false}
            defaultCurrent={LIMIT}
            current={filter.page}
            onChange={handleChangePage}
            showLessItems
            total={dataResponse.totalCount}
            pageSize={LIMIT}
          />
        )}
      </div>

      <Modal
        title={valueUserSelected?.action + ' idol'}
        open={isModalUpdateRequestIdol}
        onOk={handleUpdateRequestIdol}
        onCancel={handleCancelRequestIdol}
      >
        <p>
          Bạn sẽ {valueUserSelected?.action.toLowerCase()} cho idol "{valueUserSelected?.username}"
          ?
        </p>
      </Modal>
    </div>
  );
};

export default RequestIdolManager;
