import { Button, Form, Input, message } from 'antd';
import logo from 'assets/images/logo.png';
import { REQUIRED } from 'constants/rule-form';
import { useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import styles from './styles.module.scss';

interface FormValues {
  email: string;
}

const SendMailPW = () => {
  const [loading, setLoading] = useState(false);

  const onSendEmailForgotPW = async (values: FormValues) => {
    const data = {
      email: values.email,
    };
    setLoading(true);
    try {
      await AxiosUtils.post(API.sendEmailForgotPW, data);
      message.success('Please check your mail');
    } catch (error: any) {
      console.log(error);
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.boxSendMailPw}>
      <Form
        layout="vertical"
        name="login-form"
        className={styles.sendMailPwForm}
        onFinish={onSendEmailForgotPW}
      >
        <div className={styles.logo}>
          <img src={logo} alt="" />
        </div>
        <h2>パスワード再発行</h2>
        <div className={styles.des}>
          登録されているメールアドレス宛てに、パスワードを送信します。
        </div>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            REQUIRED,
            {
              type: 'email',
              message: 'Please enter your email',
            },
            {
              max: 255,
              message: 'Email input needs to be less than 255 characters',
            },
          ]}
        >
          <Input className={styles.sendMailInput} />
        </Form.Item>

        <Button
          loading={loading}
          disabled={loading}
          className={styles.btnSend}
          type="primary"
          block
          size="middle"
          htmlType="submit"
        >
          送信
        </Button>
      </Form>
    </div>
  );
};

export default SendMailPW;
