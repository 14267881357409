import { DeleteOutlined } from '@ant-design/icons';
import { Button, Image, Modal, Spin } from 'antd';
import { FILE_FORMAT, UPLOAD_TYPE } from 'constants/common';
import { useRef, useState } from 'react';
import { FileUploadPropsTypes } from 'types/common';
import { v4 as uuidv4 } from 'uuid';
import './style.scss';
import { uploadFileFormData } from 'service/common';
import { SERVER_FILE_ENDPOINT } from 'constants/envs';

const FileUpload = ({ onChange, value, type, maxFileSize }: FileUploadPropsTypes) => {
  const fileUrlImageRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const isUploadImage = type === UPLOAD_TYPE.IMAGE;
  const filePreview = value?.fileUrl;
  const showFileResult = filePreview || '';

  const fileAccept = () => {
    switch (type) {
      case UPLOAD_TYPE.IMAGE:
        return 'image/png, image/jpeg, image/jpg';
      case UPLOAD_TYPE.AUDIO:
        return 'audio/*';
      case UPLOAD_TYPE.VIDEO:
        return 'video/*';
      default:
        return 'image/png, image/jpeg, image/jpg';
    }
  };

  const getFileExtension = (fileName: string) => {
    const lastDot = fileName.lastIndexOf('.');
    return fileName.substring(lastDot + 1);
  };

  const onFileChange = async (e: any) => {
    try {
      if (!e.target.files || e.target.files.length === 0) return;
      const fileDetail = e.target.files[0];
      const fileExtension = getFileExtension(fileDetail.name);

      if (!FILE_FORMAT[type].includes(fileExtension.toLowerCase())) {
        onChange({
          fileKey: '',
          fileUrl: '',
          error: 'File format not support',
        });
        return;
      }

      const filename = fileDetail.name;
      const newFileName = `${uuidv4()}-${filename}`;
      const newFile = new File([fileDetail], newFileName, {
        type: fileDetail.type,
        lastModified: fileDetail.lastModified,
      });

      const fileSize = e.target.files[0].size;
      const fileSizeMb = fileSize / (1024 * 1024);
      if (fileSizeMb > maxFileSize) {
        onChange({
          fileKey: '',
          fileUrl: '',
          error: `The file can not exceed ${maxFileSize} MB.`,
        });
        return;
      }
      setLoading(true);
      const fileUploaded = await uploadFileFormData(newFile);
      const fileUrl = fileUploaded?.url ? `${SERVER_FILE_ENDPOINT}/${fileUploaded.url}` : '';
      const fileKey = fileUploaded?.url || '';

      onChange({
        fileKey,
        fileUrl,
      });
      setLoading(false);
    } catch (error: any) {
      onChange({
        fileKey: '',
        fileUrl: '',
        error,
      });
      setLoading(false);
    }
  };

  const onDeleteFile = async () => {
    try {
      fileUrlImageRef.current.value = null;
      onChange({
        fileKey: '',
        fileUrl: '',
      });
    } catch (error: any) {
      onChange({
        fileKey: '',
        fileUrl: '',
        error,
      });
    }
  };

  const renderPreviewFile = (fileUrl: string) => {
    switch (type) {
      case UPLOAD_TYPE.IMAGE:
        return <Image className="file-upload__img" src={fileUrl} alt="file-upload-image" />;
      case UPLOAD_TYPE.AUDIO:
        return (
          <audio controls>
            <source src={fileUrl} />
          </audio>
        );
      default:
        return <img className="file-upload__img" src={fileUrl} alt="file-upload-image" />;
    }
  };

  return (
    <div className="file-upload">
      {loading ? (
        <div className="file-upload__loading">
          <Spin />
        </div>
      ) : (
        <div>
          <div>
            {showFileResult && (
              <div className="file-upload__result">
                <div className="file-upload__action"></div>
                {filePreview && (
                  <div
                    className={
                      isUploadImage ? 'file-upload__image-preview' : 'file-upload__audio-preview'
                    }
                  >
                    <Button
                      title="delete"
                      className="file-upload__delete-btn"
                      onClick={onDeleteFile}
                    >
                      <DeleteOutlined />
                    </Button>
                    {renderPreviewFile(filePreview)}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <div>
        <input
          ref={fileUrlImageRef}
          accept={fileAccept()}
          type="file"
          placeholder="Add your file"
          onChange={onFileChange}
        />
      </div>
    </div>
  );
};

export default FileUpload;
