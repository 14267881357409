import { useSelector } from 'react-redux';
import styles from '../styles.module.scss';

declare global {
  interface Window {
    ethereum?: any;
  }
}

const Header = () => {
  //   const user = useSelector(getUserDetail);
  const user = { role: 'IDOL', name: 'Son Tung MTP' };

  return (
    <div className={styles.header}>
      <div className={styles.boxInfo}>
        <span>Role： {user?.role}</span>
        <div className={styles.name}>
          {user.name}
          {/* <div className={styles['profile-menu']}>
            <ProfileMenu />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
