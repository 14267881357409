import { Button, Modal, notification, Pagination, Table, Tooltip } from 'antd';
import PageTitle from 'components/page-title';
import { SERVER_FILE_ENDPOINT } from 'constants/envs';
import React, { useEffect, useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import DefaultImage from 'assets/images/default-img.png';
import styles from './styles.module.scss';
import CustomButton from 'components/custom-button';
import ModalCreateEdit from './component/ModalCreateEdit';
import { ColumnsType } from 'antd/lib/table';
import { shortenName } from '../../utils/short-name';
import { formatedDate } from '../../utils/common';
import moment from 'moment';
import { LiveShowFilter } from '../liveshows-manager';
import { PlusOutlined } from '@ant-design/icons';
import ModalCreateImageAds from './component/ModalCreateImageAds';

const LIMIT = 10;

const initFilter = { page: 1, limit: LIMIT };

const AdsManager = () => {
  const [isOpenModalEditAndCreate, setIsOpenModalEditAndCreate] = useState(false);
  const [ads, setAds] = useState({
    isDisplay: false,
    image: null,
  });
  const [statusUpdateData, setStatusUpdateData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataResponse, setDataResponse] = useState<any>({
    items: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState(initFilter as LiveShowFilter);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const res: any = await AxiosUtils.get(API.ads);
        setAds(res?.data);
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      }
    };

    const fetchListImageAds = async () => {
      setLoading(true);
      try {
        const res: any = await AxiosUtils.get(API.listAds, {
          ...filter,
        });
        setDataResponse({ items: res?.data?.data, totalCount: res?.data?.metadata?.total });
      } finally {
        setLoading(false);
      }
    };

    fetchAds();
    fetchListImageAds();
  }, [statusUpdateData]);

  const handleOkDeleteModalAds = async () => {
    setLoading(true);
    try {
      await AxiosUtils._delete(API.deleteAds, { ids: selectedRowKeys });
      onChangeStatusUpdateData();
      notification.success({
        message: 'Success',
        description: 'Xóa thành công!',
        duration: 4,
      });
      setSelectedRowKeys([]);
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    } finally {
      setLoading(false);
      setIsModalDeleteOpen(false);
    }
  };

  const onChangeStatusUpdateData = () => setStatusUpdateData(!statusUpdateData);
  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '5%',
      ellipsis: true,
      key: 'id',
    },
    {
      title: 'Ảnh',
      dataIndex: 'image',
      key: 'image',
      render: (value) => (
        <img
          width={136}
          height={72}
          style={{ objectFit: 'cover' }}
          src={`${SERVER_FILE_ENDPOINT}/${value}`}
          alt=""
        />
      ),
    },

    {
      title: 'Link',
      dataIndex: 'url',
      key: 'url',
      render: (value) => (
        <Tooltip title={value}>
          <span>{shortenName(value || '', 70) || ''}</span>
        </Tooltip>
      ),
    },

    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => (
        <span>
          {formatedDate(value)} - {moment(value).format('LT')}
        </span>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleOpenDeleteModal = () => {
    setIsModalDeleteOpen(true);
  };

  const handleChangePage = (page: number) => {
    setFilter((preState: any) => ({ ...preState, page }));
  };

  const handleCancelDeleteModal = () => {
    setIsModalDeleteOpen(false);
  };

  const handleChangeStatusAds = async () => {
    try {
      setLoading(true);
      const res: any = await AxiosUtils.patch(`${API.ads}/1`, { isDisplay: !ads.isDisplay });
      if (res?.status === 200) {
        setStatusUpdateData(!statusUpdateData);
        notification.success({
          message: 'Success',
          description: 'Sửa thành công!',
          duration: 4,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div>
      <PageTitle title="Setting quảng cáo" />
      <div className={styles.boxContent}>
        <div className={styles.text}>
          - Trạng thái có hiện quảng cáo hay không: {ads?.isDisplay ? 'Có' : 'Không'}
        </div>
        <div>
          <CustomButton
            style={{ marginBottom: 10, marginRight: 10 }}
            text="Bật tắt quảng cáo"
            bgColor="primary-green"
            htmlType="button"
            onClick={handleChangeStatusAds}
          />

          <CustomButton
            onClick={() => setIsOpenModalEditAndCreate(true)}
            bgColor="primary-green"
            text="Thêm Hình ảnh Ads"
            icon={<PlusOutlined />}
          />
        </div>
      </div>

      <div className="custom-table custom-form">
        <Button
          type="primary"
          onClick={handleOpenDeleteModal}
          disabled={!hasSelected}
          loading={loading}
        >
          Xóa
        </Button>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Đã chọn ${selectedRowKeys.length} Image` : ''}
        </span>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          rowKey="id"
          pagination={false}
          dataSource={dataResponse.items}
          scroll={{ x: 600 }}
          loading={loading}
        />
        {dataResponse.totalCount > LIMIT && (
          <Pagination
            showSizeChanger={false}
            defaultCurrent={LIMIT}
            current={filter.page}
            onChange={handleChangePage}
            showLessItems
            total={dataResponse.totalCount}
            pageSize={LIMIT}
          />
        )}
      </div>

      {isOpenModalEditAndCreate && (
        <ModalCreateImageAds
          isOpen={isOpenModalEditAndCreate}
          handleCancelCreateEditModal={() => setIsOpenModalEditAndCreate(false)}
          onChangeStatusUpdateData={onChangeStatusUpdateData}
        />
      )}
      <Modal
        title="Xóa Image"
        open={isModalDeleteOpen}
        onOk={handleOkDeleteModalAds}
        onCancel={handleCancelDeleteModal}
      >
        <p>Bạn có thực sự muốn xóa {selectedRowKeys.length} Image.</p>
      </Modal>
    </div>
  );
};

export default AdsManager;
