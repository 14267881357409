import { Button, Form, Input, message } from 'antd';
import logo from 'assets/images/logo.png';
import { REQUIRED } from 'constants/rule-form';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API } from 'service/api-route';
import { paths } from 'routes/routes';
import AxiosUtils from 'utils/axios-utils';
import styles from './styles.module.scss';

interface FormValues {
  password: string;
}

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const onSendEmailForgotPW = async (values: FormValues) => {
    const data = {
      tokenResetPassword: searchParams.get('tokenResetPassword'),
      adminId: searchParams.get('adminId'),
      newPassword: values.password,
    };
    setLoading(true);
    try {
      await AxiosUtils.post(API.resetPassword, data);
      message.success('Reset password successfully');
      navigate(paths.login);
    } catch (error) {
      message.error('Something went wrong. Please enter email forgot password.');
      navigate(paths.sendMailForgotPw);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.boxSendMailPw}>
      <Form
        layout="vertical"
        name="login-form"
        className={styles.sendMailPwForm}
        onFinish={onSendEmailForgotPW}
      >
        <div className={styles.logo}>
          <img src={logo} alt="" />
        </div>
        <h2>パスワード変更</h2>
        <Form.Item
          label="新しいパスワード必須"
          name="password"
          rules={[
            REQUIRED,
            {
              pattern: new RegExp(
                '(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$'
              ),
              message:
                'Password must have at least 8 characters, 1 uppercase, 1 lowercase, 1 special character.',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="パスワード確認必須"
          name="comparePw"
          rules={[
            REQUIRED,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Button
          loading={loading}
          disabled={loading}
          className={styles.btnSend}
          type="primary"
          block
          size="middle"
          htmlType="submit"
        >
          ログイン画面へ
        </Button>
      </Form>
    </div>
  );
};

export default ResetPassword;
