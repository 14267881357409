import 'antd/dist/reset.css';
import SidebarLayout from 'layouts';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from 'routes/protected-route';
import { mainRoutes } from 'routes/routes';
import './styles/index.scss';
import { RouteObject } from './types/route';

function App() {
  return (
    <div className="App">
      <Routes>
        {mainRoutes.map((route: RouteObject, index: number) => {
          const Component = route.component;
          if (route.isPublish) {
            return <Route key={index} path={route.path} element={<Component />} />;
          }
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoute>
                  <SidebarLayout>
                    <Component />
                  </SidebarLayout>
                </ProtectedRoute>
              }
            />
          );
        })}
      </Routes>
    </div>
  );
}

export default App;
