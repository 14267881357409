import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  notification,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RangePickerProps } from 'antd/es/date-picker';
import CustomButton from 'components/custom-button';
import CustomizedUpload from 'components/customized-upload';
import { REGEX_USERNAME, ROLE_USER, UPLOAD_TYPE } from 'constants/common';
import { REQUIRED } from 'constants/rule-form';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import { CHECK_FILE } from 'utils/rule-form';
import DebounceSelect from '../../../components/debounce-select/select';
import styles from '../styles.module.scss';
const { Option } = Select;
interface DataPost {
  agencyId: any;
  idolId: any;
}

interface Props {
  isOpen: boolean;
  handleCancelCreateEditModal: () => void;
  onChangeStatusUpdateData: () => void;
  valueUserSelected: any;
}

const ModalCreateEdit = ({
  isOpen,
  valueUserSelected,
  handleCancelCreateEditModal,
  onChangeStatusUpdateData,
}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const filterOptions = useCallback(async (search: string) => {
    const response: any = await AxiosUtils.get(`${API.searchUser}?query=${search}`);
    return response.data.map((item: any) => ({
      id: item.id,
      label: item.username,
      value: item.id,
    }));
  }, []);

  const filterOptionsAgency = useCallback(async (search: string) => {
    const response: any = await AxiosUtils.get(`${API.searchAgency}?query=${search}`);
    return response.data.map((item: any) => ({
      id: item.id,
      label: item.username,
      value: item.id,
    }));
  }, []);

  const onFinish = async (values: DataPost) => {
    const newValues = { ...values };
    if (newValues.agencyId) {
      newValues.agencyId = values?.agencyId?.value;
    }

    if (newValues.idolId) {
      newValues.idolId = values?.idolId?.value;
    }

    setLoading(true);
    try {
      await AxiosUtils.put(API.createConversation, newValues);
      handleCancelCreateEditModal();
      onChangeStatusUpdateData();
      notification.success({
        message: 'Success',
        description: 'Tạo thành công!',
        duration: 4,
      });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        forceRender
        open={isOpen}
        title={'Thêm idol cho Agency Quản lý'}
        onCancel={handleCancelCreateEditModal}
        width={800}
        getContainer={false}
        maskClosable={false}
        footer={false}
      >
        <Form
          name="form"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <label htmlFor="">Chọn Agency</label>

          <Row className="d-flex justify-center">
            <Col span={24}>
              <Form.Item name="agencyId" wrapperCol={{ span: 24 }} rules={[REQUIRED]}>
                <DebounceSelect
                  placeholder="Chọn Agency Quản lý idol"
                  fetchOptions={filterOptionsAgency}
                />
              </Form.Item>
            </Col>
          </Row>

          <label htmlFor="">Chọn Idol</label>

          <Row className="d-flex justify-center">
            <Col span={24}>
              <Form.Item name="idolId" wrapperCol={{ span: 24 }} rules={[REQUIRED]}>
                <DebounceSelect placeholder="Chọn Idol" fetchOptions={filterOptions} />
              </Form.Item>
            </Col>
          </Row>

          <div className="d-flex justify-center">
            <Space size="large">
              <CustomButton
                bgColor="secondary-green"
                text="Submit"
                htmlType="submit"
                loading={loading}
              />
              <CustomButton
                text="Hủy Bỏ"
                bgColor="primary-grey"
                htmlType="button"
                onClick={handleCancelCreateEditModal}
              />
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCreateEdit;
