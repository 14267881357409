import axios from 'axios';

import { SERVER_API_ENDPOINT } from 'constants/envs';
import { StorageUtils } from './local-storage';

const AxiosClient = axios.create({
  baseURL: SERVER_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

AxiosClient.defaults.withCredentials = true;

AxiosClient.interceptors.request.use((req) => {
  return req;
});

AxiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: any) => {
    if (
      error &&
      error.response &&
      error.response.status &&
      (error.response.status === 403 || error.response.status === 401)
    ) {
      localStorage.removeItem('SESSION');
      window.location.href = '/login';
      throw error.response.data;
    } else {
      throw error.response.data;
    }
  }
);

const get = <ReqType, ResType>(
  url: string,
  params?: ReqType,
  customHeaders?: any
): Promise<ResType> => {
  return AxiosClient.get(url, { params, headers: customHeaders });
};

function post<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: any
): Promise<ResType> {
  return AxiosClient.post(url, data, { headers: customHeaders });
}

function postS3<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: any
): Promise<ResType> {
  return AxiosClient.post(url, data, { headers: customHeaders });
}

async function put<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: any
): Promise<ResType> {
  return AxiosClient.put(url, data, { headers: customHeaders });
}

async function patch<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: any
): Promise<ResType> {
  return AxiosClient.patch(url, data, { headers: customHeaders });
}

async function _delete<ReqType, ResType>(url: string, payload: ReqType): Promise<ResType> {
  return AxiosClient.delete(url, { data: payload });
}

const AxiosUtils = { get, post, postS3, put, patch, _delete };
export default AxiosUtils;
