import { Col, DatePicker, Form, Input, Row } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import React from 'react';
import CustomButton from 'components/custom-button';
import moment from 'moment';

type FilterProps = {
  onSubmit: (value: any) => void;
};
const { RangePicker } = DatePicker;

const initialValues = {
  datePicker: [],
};

const Filter: React.FC<any> = (props) => {
  const [form] = Form.useForm();
  const { onSubmit } = props;

  const onClearFilter = () => {
    form.resetFields();
    onSubmit({ ...form.getFieldsValue(), startDate: '', endDate: '' });
  };

  const onFormSubmit = (value: typeof initialValues) => {
    console.log('thien 2');
    const { datePicker } = value;
    let startDate = '';
    let endDate = '';

    if (datePicker) {
      const [start, end] = datePicker as moment.Moment[];

      startDate = start?.format('YYYY-MM-DD');
      endDate = end?.format('YYYY-MM-DD');
    }

    onSubmit({ startDate, endDate });
  };

  return (
    <Form form={form} name="register" onFinish={onFormSubmit} initialValues={initialValues}>
      <Row gutter={[24, 0]} className="custom-form">
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item label="Tìm kiếm theo ngày" name="datePicker">
            <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
          </Form.Item>
        </Col>
        <Col style={{ paddingTop: 25 }}>
          <Form.Item className="text-right mt-10">
            <CustomButton
              bgColor="secondary-green"
              htmlType="submit"
              text="Tìm kiếm"
              icon={<SearchOutlined />}
            />
            <CustomButton
              text="Clear"
              bgColor="primary-grey"
              icon={<CloseOutlined />}
              className="ml-5"
              onClick={onClearFilter}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
