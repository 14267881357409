import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input, InputNumber,
  Modal,
  notification,
  Row,
  Select,
  Space,
  TimePicker,
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import CustomButton from 'components/custom-button';
import CustomizedUpload from 'components/customized-upload';
import { REGEX_USERNAME, ROLE_USER, UPLOAD_TYPE } from 'constants/common';
import { REQUIRED } from 'constants/rule-form';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import { CHECK_FILE } from 'utils/rule-form';
import DebounceSelect from '../../../components/debounce-select/select';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import moment from 'moment';
import { SERVER_FILE_ENDPOINT } from '../../../constants/envs';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import styles from '../../news-manager/styles.module.scss';
import { editorConfig } from '../../news-manager/component/ModalCreateEdit';
import JoditEditor from 'jodit-react';

interface Props {
  isOpen: boolean;
  handleCancelCreateEditModal: () => void;
  onChangeStatusUpdateData: () => void;
  valueUserSelected: any;
}

// eslint-disable-next-line arrow-body-style
// Can not select days before today and today
const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current.add(1, 'day') < dayjs().endOf('day');
};

console.log('first,', dayjs().endOf('day'));
const disabledDateTime = () => ({
  // disabledHours: () => range(0, 24).splice(0, moment().hours() + 1),
  // disabledMinutes: () => range(0, 60),
  // disabledSeconds: () => [55, 56],
});

const ModalCreateAndEditVote = ({
  isOpen,
  valueUserSelected,
  handleCancelCreateEditModal,
  onChangeStatusUpdateData,
}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [ruleVote, setRuleVote] = useState<any>('');
  const [introduction, setIntroduction] = useState<any>('');

  useEffect(() => {}, []);

  const onBlurContent = useCallback(
    (newContent) => {
      setRuleVote(newContent);
    },
    [setRuleVote]
  );

  const onBlurIntroduction = useCallback(
      (newContent) => {
        setIntroduction(newContent);
      },
      [setIntroduction]
  );

  const onChange = useCallback((newContent) => {
    console.log(newContent);
  }, []);

  const onChangeIntroduction = useCallback((newContent) => {
    console.log(newContent);
  }, []);

  useEffect(() => {
    if (valueUserSelected) {
      form.setFieldsValue({
        title: valueUserSelected?.title,
        score: valueUserSelected.score,
        background: valueUserSelected?.background
          ? {
              fileKey: valueUserSelected?.background,
              fileUrl: `${SERVER_FILE_ENDPOINT}/${valueUserSelected.background}`,
            }
          : null,
        startDate: dayjs(valueUserSelected?.startDate),
        endDate: dayjs(valueUserSelected?.endDate),
        isVoteDisabled: valueUserSelected?.isVoteDisabled || false,
        startTimeVote: dayjs(valueUserSelected?.startTimeVote, 'HH:mm'),
        endTimeVote: dayjs(valueUserSelected?.endTimeVote, 'HH:mm'),
        vote_settings: valueUserSelected?.vote_settings,
      });
      setRuleVote(valueUserSelected?.ruleVote || '');
      setIntroduction(valueUserSelected?.introduction || '');
    }
  }, [form, valueUserSelected]);

  const onFinish = async (values: any) => {
    setLoading(true);

    if (values.startTimeVote) {
      values.startTimeVote = dayjs(values.startTimeVote).format('HH:mm');
    }

    if (values.endTimeVote) {
      values.endTimeVote = dayjs(values.endTimeVote).format('HH:mm');
    }

    values.isVoteDisabled = values?.isVoteDisabled || false

    values.ruleVote = ruleVote;
    values.introduction = introduction;
    values.background = values?.background?.fileKey;


    setLoading(true);
    if (valueUserSelected?.id) {
      try {
        await AxiosUtils.put(`${API.updateVote}/${valueUserSelected.id}`, values);
        handleCancelCreateEditModal();
        onChangeStatusUpdateData();
        notification.success({
          message: 'Success',
          description: 'Sửa thành công!',
          duration: 4,
        });
      } catch (e) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res: any = await AxiosUtils.post(API.voteCreate, values);
        if (res?.status === 201) {
          handleCancelCreateEditModal();
          onChangeStatusUpdateData();
          notification.success({
            message: 'Success',
            description: 'Tạo thành công!',
            duration: 4,
          });
        }
      } catch (e) {
        console.log(e);
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        forceRender
        open={isOpen}
        title={'Thêm Sự kiện'}
        onCancel={handleCancelCreateEditModal}
        width={800}
        getContainer={false}
        footer={false}
      >
        <Form
          name="form"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <label htmlFor="title">Tiêu đề sự kiện</label>
          <Form.Item rules={[REQUIRED]} name="title" wrapperCol={{ span: 24 }}>
            <Input size={'middle'} placeholder="Nhập tiêu đề sự kiện" />
          </Form.Item>
          <label className={styles.labelInput} htmlFor="background">
            Background
          </label>
          <Form.Item name="background" wrapperCol={{ span: 24 }} rules={[CHECK_FILE]}>
            <CustomizedUpload type={UPLOAD_TYPE.IMAGE} maxFileSize={5} />
          </Form.Item>
          <div className="d-flex">
            <div>
              <label htmlFor="startDate">Ngày bắt đầu</label>
              <Form.Item rules={[REQUIRED]} name="startDate" wrapperCol={{ span: 24 }}>
                <DatePicker
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                  disabledTime={disabledDateTime}
                />
              </Form.Item>
            </div>
            <div style={{ marginLeft: 50 }}>
              <label htmlFor="startTimeVote">Thời gian bắt đầu vote</label>
              <Form.Item rules={[REQUIRED]} name="startTimeVote" wrapperCol={{ span: 24 }}>
                <TimePicker format={'HH:mm'} showSecond={false} />
              </Form.Item>
            </div>
            <div style={{ marginLeft: 50 }}>
              <label htmlFor="endDate">Ngày kết thúc</label>
              <Form.Item rules={[REQUIRED]} name="endDate" wrapperCol={{ span: 24 }}>
                <DatePicker
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                  disabledTime={disabledDateTime}
                />
              </Form.Item>
            </div>
            <div style={{ marginLeft: 50 }}>
              <label htmlFor="endTimeVote">Thời gian kết thúc</label>
              <Form.Item rules={[REQUIRED]} name="endTimeVote" wrapperCol={{ span: 24 }}>
                <TimePicker format={'HH:mm'} showSecond={false} />
              </Form.Item>
            </div>
          </div>
          <>
            <label className={styles.labelInput} htmlFor="score">
              Setting điểm khi người chơi vote
            </label>
            <Form.Item
                name="score"
                rules={[
                  REQUIRED
                ]}
                wrapperCol={{ span: 16 }}
            >
              <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  placeholder="Nhập kim cương"
                  size="middle"
                  style={{ width: 300 }}
              />
            </Form.Item>
          </>
          <Form.List name="vote_settings">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Form.Item
                      {...restField}
                      wrapperCol={{ span: 24 }}
                      name={[name, 'role']}
                      rules={[{ required: true, message: 'Missing Role name' }]}
                    >
                      <Input placeholder="Role Name" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      wrapperCol={{ span: 24 }}
                      name={[name, 'total']}
                      rules={[{ required: true, message: 'Missing Total Vote' }]}
                    >
                      <Input placeholder="Total Vote" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add Setting
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Col span={16}>
            <Form.Item
              name="isVoteDisabled"
              valuePropName="checked"
            >
            <Checkbox>
              Có ẩn sự kiện vote hay không ?
            </Checkbox>
            </Form.Item>
          </Col>
          <br/>

          <label className={styles.labelInput} htmlFor="ruleVote">
            Giới thiệu
          </label>
          <div
              style={{
                maxWidth: editorConfig.width,
                margin: '0 auto',
                marginBottom: 10,
              }}
          >
            <JoditEditor
                value={introduction}
                config={editorConfig as any}
                onBlur={onBlurIntroduction}
                onChange={onChangeIntroduction}
            />
          </div>

          <label className={styles.labelInput} htmlFor="ruleVote">
            Thể lệ
          </label>
          <div
            style={{
              maxWidth: editorConfig.width,
              margin: '0 auto',
              marginBottom: 10,
            }}
          >
            <JoditEditor
              value={ruleVote}
              config={editorConfig as any}
              onBlur={onBlurContent}
              onChange={onChange}
            />
          </div>

          <div className="d-flex justify-center">
            <Space size="large">
              <CustomButton
                bgColor="secondary-green"
                text="Submit"
                htmlType="submit"
                loading={loading}
              />
              <CustomButton
                text="Hủy Bỏ"
                bgColor="primary-grey"
                htmlType="button"
                onClick={handleCancelCreateEditModal}
              />
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCreateAndEditVote;
