import React from 'react';
import FileUpload from 'components/file-upload';

interface Props {
  value?: {
    fileKey: string;
    fileUrl: string;
  };
  onChange?: any;
  type: string;
  maxFileSize: number;
}

const CustomizedUpload = ({ value, onChange, type, maxFileSize }: Props) => {
  return (
    <div>
      <FileUpload value={value} onChange={onChange} type={type} maxFileSize={maxFileSize} />
    </div>
  );
};

export default CustomizedUpload;
