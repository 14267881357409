import { Form, Input, Modal, notification, Select, Space } from 'antd';
import CustomButton from 'components/custom-button';
import { useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import styles from '../styles.module.scss';
import { CHECK_FILE } from 'utils/rule-form';
import CustomizedUpload from 'components/customized-upload';
import { UPLOAD_TYPE } from 'constants/common';

interface DataPost {
  image: any;
  url?: string;
}

interface Props {
  isOpen: boolean;
  handleCancelCreateEditModal: () => void;
  onChangeStatusUpdateData: () => void;
}

const ModalCreateImageAds = ({
  isOpen,
  handleCancelCreateEditModal,
  onChangeStatusUpdateData,
}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: DataPost) => {
    values.image = values?.image?.fileKey;
    setLoading(true);
    try {
      const res: any = await AxiosUtils.post(`${API.postAds}`, values);
      if (res?.status === 201) {
        handleCancelCreateEditModal();
        onChangeStatusUpdateData();
        notification.success({
          message: 'Success',
          description: 'Thêm thành công!',
          duration: 4,
        });
      }
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        forceRender
        open={isOpen}
        title={'Thêm hình ảnh ads'}
        onCancel={handleCancelCreateEditModal}
        width={800}
        getContainer={false}
        footer={false}
      >
        <Form
          name="form"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <label className={styles.labelInput} htmlFor="image">
            Ảnh
          </label>
          <Form.Item name="image" wrapperCol={{ span: 24 }} rules={[CHECK_FILE]}>
            <CustomizedUpload type={UPLOAD_TYPE.IMAGE} maxFileSize={20} />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="url">
            Link liên kết
          </label>
          <Form.Item
            name="url"
            rules={[
              {
                max: 500,
                message: 'Không được quá 200 kí tự',
              },
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="Nhập link liên kết" size="large" />
          </Form.Item>

          <div className="d-flex justify-center">
            <Space size="large">
              <CustomButton
                bgColor="secondary-green"
                text="Submit"
                htmlType="submit"
                loading={loading}
              />
              <CustomButton
                text="Hủy Bỏ"
                bgColor="primary-grey"
                htmlType="button"
                onClick={handleCancelCreateEditModal}
              />
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCreateImageAds;
