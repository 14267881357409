import React, { useEffect, useState } from 'react';
import styles from '../job-notification/styles.module.scss';
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Pagination,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { REQUIRED } from '../../constants/rule-form';
import { ROLE_USER } from '../../constants/common';
import CustomButton from '../../components/custom-button';
import AxiosUtils from '../../utils/axios-utils';
import { API } from '../../service/api-route';
import { ColumnsType } from 'antd/lib/table';
import { shortenName } from '../../utils/short-name';
import { SERVER_FILE_ENDPOINT } from '../../constants/envs';
import { formatedDate } from '../../utils/common';
import { UserFilter } from '../news-manager';

const { Option } = Select;

const LIMIT = 10;

const initFilter = { page: 1, limit: LIMIT };

interface DataPost {
  title: string;
  content: string;
  roles: any;
}

function NotificationManager() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [dataResponse, setDataResponse] = useState<any>({
    items: [],
    totalCount: 0,
  });
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [filter, setFilter] = useState(initFilter as UserFilter);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [statusUpdateData, setStatusUpdateData] = useState(false);
  const handleChangePage = (page: number) => {
    setFilter((preState: any) => ({ ...preState, page }));
  };

  const onFinish = async (values: DataPost) => {
    try {
      await AxiosUtils.post(API.pushNotification, { ...values, type: 'COMBAT', isSave: true });
      notification.success({
        message: 'Success',
        description: 'Gửi thông báo thành công!',
        duration: 4,
      });
      form.resetFields();
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        const res: any = await AxiosUtils.get(API.adminNotification, {
          ...filter,
        });
        setDataResponse({ items: res?.data?.data, totalCount: res?.data?.metadata?.total });
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [filter, statusUpdateData]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '5%',
      ellipsis: true,
      key: 'id',
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      key: 'title',
      render: (value) => (
        <Tooltip title={value}>
          <span>{shortenName(value || '', 60) || ''}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Nội dung',
      dataIndex: 'content',
      key: 'content',
      render: (value) => (
        <Tooltip title={value}>
          <span>{shortenName(value || '', 60) || ''}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Gửi đến nhóm người dùng',
      dataIndex: 'group',
      key: 'group',
      render: (value) => (
        <Tooltip title={value}>
          <span>{shortenName(value || '', 60) || ''}</span>
        </Tooltip>
      ),
    },

    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => <span>{formatedDate(value)}</span>,
    },
  ];

  const handelOpenDeleteModal = () => {
    setIsModalDeleteOpen(true);
  };

  const onChangeStatusUpdateData = () => setStatusUpdateData(!statusUpdateData);

  const handleCancelDeleteModal = () => {
    setIsModalDeleteOpen(false);
  };

  const handleOkDeleteModal = async () => {
    setLoading(true);
    try {
      await AxiosUtils._delete(API.deleteNotification, { ids: selectedRowKeys });
      onChangeStatusUpdateData();
      notification.success({
        message: 'Success',
        description: 'Xóa thành công!',
        duration: 4,
      });
      setSelectedRowKeys([]);
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    } finally {
      setLoading(false);
      setIsModalDeleteOpen(false);
    }
  };

  return (
    <div>
      <Form
        name="form"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <label className={styles.labelInput} htmlFor="title">
          Tiêu đề
        </label>
        <Form.Item
          name="title"
          rules={[
            REQUIRED,
            {
              max: 200,
              message: 'Không được quá 200 kí tự',
            },
          ]}
          wrapperCol={{ span: 24 }}
        >
          <Input placeholder="Nhập tiêu đề" />
        </Form.Item>

        <label className={styles.labelInput} htmlFor="content">
          Nội dung
        </label>
        <Form.Item
          rules={[{ required: true, message: 'Nội dung này bắt buộc' }]}
          name="content"
          wrapperCol={{ span: 24 }}
        >
          <Input.TextArea placeholder={'Nội dung'} showCount maxLength={300} />
        </Form.Item>

        <label className={styles.labelInput} htmlFor="group">
          Chọn loại người dùng để gửi thông báo
        </label>
        <Form.Item rules={[REQUIRED]} name="roles" wrapperCol={{ span: 24 }}>
          <Select mode="multiple" value={''} placeholder="Chọn loại người dùng để gửi thông báo">
            <Option value={ROLE_USER.USER}>Người dùng</Option>
            <Option value={ROLE_USER.MOD}>Nhân viên</Option>
            <Option value={ROLE_USER.AGENCY}>Đối tác</Option>
            <Option value={ROLE_USER.CREATOR}>Nhà sáng taọ</Option>
          </Select>
        </Form.Item>

        <div className="d-flex justify-center">
          <Space size="large">
            <CustomButton
              bgColor="secondary-green"
              text="Submit"
              htmlType="submit"
              loading={loading}
            />
          </Space>
        </div>
      </Form>

      <div className="custom-table custom-form">
        <Button
          type="primary"
          onClick={handelOpenDeleteModal}
          disabled={!hasSelected}
          loading={loading}
        >
          Xóa
        </Button>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Đã chọn ${selectedRowKeys.length} tin tức` : ''}
        </span>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          rowKey="id"
          pagination={false}
          dataSource={dataResponse.items}
          scroll={{ x: 600 }}
          loading={loading}
        />
        {dataResponse.totalCount > LIMIT && (
          <Pagination
            showSizeChanger={false}
            defaultCurrent={LIMIT}
            current={filter.page}
            onChange={handleChangePage}
            showLessItems
            total={dataResponse.totalCount}
            pageSize={LIMIT}
          />
        )}
        <Modal
          title="Xóa Notification"
          open={isModalDeleteOpen}
          onOk={handleOkDeleteModal}
          onCancel={handleCancelDeleteModal}
        >
          <p>Bạn có thực sự muốn xóa {selectedRowKeys.length} notification.</p>
        </Modal>
      </div>
    </div>
  );
}

export default NotificationManager;
