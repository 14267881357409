import { SORT_DIRECTION } from '../../constants/common';
import SortUpIcon from '../../assets/images/sort-up.svg';
import SortDownIcon from '../../assets/images/sort-down.svg';
import styles from './styles.module.scss';

const renderSortIcon = (sortDirection: any) => {
  if (!sortDirection) return <span>&nbsp;</span>;
  if (sortDirection === SORT_DIRECTION.UP) return <img src={SortUpIcon} alt="sort-up" />;
  return <img src={SortDownIcon} alt="sort-down" />;
};

export const renderHeaderTitle = (title: string, sortDirection: any, onSortChange: any) => (
  <div onClick={onSortChange} className="customer-table-info__head">
    <div className={styles.customerTableInfo__header}>
      <div className="customer-table-info__header__title">{title}</div>
      <div className="customer-table-info__header__icon">{renderSortIcon(sortDirection)}</div>
    </div>
  </div>
);
