import { Col, Form, notification, Row, Space } from 'antd';
import { REQUIRED } from '../../constants/rule-form';
import DebounceSelect from '../../components/debounce-select/select';
import CustomButton from '../../components/custom-button';
import { useCallback, useState } from 'react';
import AxiosUtils from '../../utils/axios-utils';
import { API } from '../../service/api-route';
import * as _ from 'lodash';

interface DataPost {
  vote: any;
  users: any;
}

function AddUserVoteManager() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const filterOptions = useCallback(async (search: string) => {
    const response: any = await AxiosUtils.get(`${API.searchUser}?query=${search}`);
    return response.data.map((item: any) => ({
      id: item.id,
      label: item.username,
      value: item.id,
    }));
  }, []);

  const filterOptionDomestic = useCallback(async (search: string) => {
    const response: any = await AxiosUtils.get(`${API.voteSearch}?query=${search}`);
    return response.data.map((item: any) => ({
      id: item.id,
      label: item.title,
      value: item.id,
    }));
  }, []);

  const onFinish = async (values: DataPost) => {
    const makeRequest = {
      voteId: values.vote.value,
      users: _.map(values?.users, 'value').toString(),
    };
    setLoading(true);
    try {
      await AxiosUtils.post(API.userJoinVote, makeRequest);
      notification.success({
        message: 'Success',
        description: 'Thêm thành viên thành công!',
        duration: 4,
      });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    } finally {
      form.resetFields();
      setLoading(false);
    }
  };

  return (
    <div>
      <Form
        name="form"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <label htmlFor="">Chọn Sự kiện</label>

        <Row className="d-flex justify-center">
          <Col span={24}>
            <Form.Item name="vote" wrapperCol={{ span: 12 }} rules={[REQUIRED]}>
              <DebounceSelect placeholder="Chọn sự kiện" fetchOptions={filterOptionDomestic} />
            </Form.Item>
          </Col>
        </Row>

        <label htmlFor="">Chọn thành viên</label>

        <Row className="d-flex justify-center">
          <Col span={24}>
            <Form.Item name="users" wrapperCol={{ span: 12 }} rules={[REQUIRED]}>
              <DebounceSelect
                mode={'multiple'}
                placeholder="Chọn thành viên"
                fetchOptions={filterOptions}
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="d-flex">
          <Space size="large">
            <CustomButton
              bgColor="secondary-green"
              text="Submit"
              htmlType="submit"
              loading={loading}
            />
          </Space>
        </div>
      </Form>
    </div>
  );
}

export default AddUserVoteManager;
