import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Button, Form, Modal, notification, Pagination, Select, Table, Tooltip } from 'antd';
import AxiosUtils from '../../utils/axios-utils';
import { API } from '../../service/api-route';
import { ColumnsType } from 'antd/lib/table';
import { shortenName } from '../../utils/short-name';
import { formatedDate } from '../../utils/common';
import { UserFilter } from '../news-manager';
import { useNavigate, useParams } from 'react-router-dom';
import { SERVER_FILE_ENDPOINT } from '../../constants/envs';
const LIMIT = 10;

const initFilter = { page: 1, limit: LIMIT };

function GroupDomesticDetailManager() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dataResponse, setDataResponse] = useState<any>({
    items: [],
    totalCount: 0,
  });

  const [infoGroup, setInfoGroup] = useState<any>(null);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [filter, setFilter] = useState(initFilter as UserFilter);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [statusUpdateData, setStatusUpdateData] = useState(false);
  const handleChangePage = (page: number) => {
    setFilter((preState: any) => ({ ...preState, page }));
  };

  useEffect(() => {
    const fetchGroup = async () => {
      setLoading(true);
      try {
        const res: any = await AxiosUtils.get(`${API.createGroup}/${id}`);
        console.log(res.data, 'group info');
        setInfoGroup(res.data);
      } finally {
        setLoading(false);
      }
    };

    fetchGroup();
  }, [id]);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const res: any = await AxiosUtils.get(`${API.userDomestic}/${id}/domestic`, {
          ...filter,
        });
        setDataResponse({ items: res?.data?.data, totalCount: res?.data?.metadata?.total });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [filter, statusUpdateData]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '5%',
      ellipsis: true,
      key: 'id',
    },
    {
      title: 'Tiktok ID',
      dataIndex: 'username',
      key: 'username',
      render: (value) => (
        <Tooltip title={value}>
          <span>{shortenName(value || '', 60) || ''}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (value) => (
        <Tooltip title={value}>
          <span>{shortenName(value || '', 60) || ''}</span>
        </Tooltip>
      ),
    },

    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => <span>{formatedDate(value)}</span>,
    },
  ];

  const handelOpenDeleteModal = () => {
    setIsModalDeleteOpen(true);
  };

  const onChangeStatusUpdateData = () => setStatusUpdateData(!statusUpdateData);

  const handleCancelDeleteModal = () => {
    setIsModalDeleteOpen(false);
  };

  const handleOkDeleteModal = async () => {
    setLoading(true);
    try {
      await AxiosUtils._delete(API.deleteNotification, { ids: selectedRowKeys });
      onChangeStatusUpdateData();
      notification.success({
        message: 'Success',
        description: 'Xóa thành công!',
        duration: 4,
      });
      setSelectedRowKeys([]);
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    } finally {
      setLoading(false);
      setIsModalDeleteOpen(false);
    }
  };

  return (
    <div>
      <img src={`${SERVER_FILE_ENDPOINT}/${infoGroup?.avatar}`} height={250} width={'100%'} />
      <div onClick={() => navigate('/domestic-management')} className={styles.wrapBack}>
        Quay lại
      </div>
      <div className={styles.wrapInfo}>
        <span className={styles.textTitleDomestic}>
          Tên gia tộc: <b>{infoGroup?.title}</b>
        </span>
        <br />
        <span className={styles.textOwner}>
          Chủ gia tộc: <b>{infoGroup?.owner?.username}</b>
        </span>
      </div>

      <div className={styles.wrapMg} />
      <div className="custom-table custom-form">
        <Button
          type="primary"
          onClick={handelOpenDeleteModal}
          disabled={!hasSelected}
          loading={loading}
        >
          Xóa
        </Button>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Đã chọn ${selectedRowKeys.length} tin tức` : ''}
        </span>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          rowKey="id"
          pagination={false}
          dataSource={dataResponse.items}
          scroll={{ x: 600 }}
          loading={loading}
        />
        {dataResponse.totalCount > LIMIT && (
          <Pagination
            showSizeChanger={false}
            defaultCurrent={LIMIT}
            current={filter.page}
            onChange={handleChangePage}
            showLessItems
            total={dataResponse.totalCount}
            pageSize={LIMIT}
          />
        )}
        <Modal
          title="Xóa Notification"
          open={isModalDeleteOpen}
          onOk={handleOkDeleteModal}
          onCancel={handleCancelDeleteModal}
        >
          <p>Bạn có thực sự muốn xóa {selectedRowKeys.length} notification.</p>
        </Modal>
      </div>
    </div>
  );
}

export default GroupDomesticDetailManager;
