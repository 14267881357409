import { Button, Form, Input } from 'antd';
import logo from 'assets/images/hp-logo.png';
import { REQUIRED } from 'constants/rule-form';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { login } from 'redux/auth/actions';
import { paths } from 'routes/routes';
import { Session } from '../../types/common';
import { RootState } from '../../types/redux';
import { useAppDisPatch } from '../../utils/common';
import { getSession } from '../../utils/session';
import './styles.scss';

interface FormValues {
  email: string;
  password: string;
}

const LoginPage = () => {
  const dispatch = useAppDisPatch();
  const navigate = useNavigate();
  const session: Session = getSession();

  useEffect(() => {
    if (session && session.accessToken) {
      navigate(paths.home);
    }
  }, [session, navigate]);

  const auth = useSelector((state: RootState) => state.auth);
  const { error, isLoading } = auth;

  const onLogin = (values: FormValues) => {
    const data = {
      username: values.email.trim(),
      password: values.password,
    };
    dispatch(login(data, navigate));
  };

  return (
    <div className="login">
      <Form layout="vertical" name="login-form" className="login__form" onFinish={onLogin}>
        <div className="login__logo">
          <img src={logo} alt="" />
        </div>
        <h2>Đăng nhập</h2>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { ...REQUIRED },
            {
              type: 'email',
              message: 'Vui lòng nhập đúng định dạng email',
            },
            {
              max: 255,
              message: 'Email cần ít hơn 255 ký tự',
            },
          ]}
          validateFirst={true}
          normalize={(value, prevVal, prevVals) => value.trim()}
        >
          <Input className="login__inputStyle" />
        </Form.Item>
        <Form.Item
          label="Mật khẩu"
          name="password"
          rules={[
            REQUIRED,
            {
              min: 8,
              message: 'Mật khẩu cần ít nhất 8 kí tự',
            },
            {
              max: 15,
              message: 'Mật khẩu cần ít hơn 16 kí tự',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Button
          loading={isLoading}
          disabled={isLoading}
          className="login__btn_login"
          type="primary"
          block
          size="middle"
          htmlType="submit"
        >
          Đăng nhập
        </Button>
        {error.message && <div className="login__error">{error.message}</div>}
      </Form>
    </div>
  );
};

export default LoginPage;
