import { Form, Input, Modal, Select, Space, notification, DatePicker } from 'antd';
import CustomButton from 'components/custom-button';
import { REQUIRED } from 'constants/rule-form';
import { useEffect, useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import styles from '../styles.module.scss';
import { TYPE_LIVE_SHOW, TYPE_MENU, UPLOAD_TYPE } from 'constants/common';
import CustomizedUpload from 'components/customized-upload';
import { CHECK_FILE } from 'utils/rule-form';
import { SERVER_FILE_ENDPOINT } from 'constants/envs';
import dayjs from 'dayjs';
const { Option } = Select;

interface DataPost {
  name: string;
  link: string;
  icon: any;
  type: string;
  position: number;
  date: string;
}

interface Props {
  isOpen: boolean;
  handleCancelCreateEditModal: () => void;
  onChangeStatusUpdateData: () => void;
  valueUserSelected: any;
}

const ModalCreateEdit = ({
  isOpen,
  valueUserSelected,
  handleCancelCreateEditModal,
  onChangeStatusUpdateData,
}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: DataPost) => {
    setLoading(true);
    if (valueUserSelected?.id) {
      try {
        const res: any = await AxiosUtils.put(`${API.groupShow}/${valueUserSelected?.id}`, values);
        if (res?.status === 200) {
          handleCancelCreateEditModal();
          onChangeStatusUpdateData();
          notification.success({
            message: 'Success',
            description: 'Sửa thành công!',
            duration: 4,
          });
        }
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res: any = await AxiosUtils.post(API.groupShow, values);
        if (res?.status === 201) {
          handleCancelCreateEditModal();
          onChangeStatusUpdateData();
          notification.success({
            message: 'Success',
            description: 'Tạo thành công!',
            duration: 4,
          });
        }
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (valueUserSelected) {
      form.setFieldsValue({
        name: valueUserSelected?.name || '',
        date: dayjs(valueUserSelected?.date),
        type: valueUserSelected?.type || 'ALL',
      });
    }
  }, [form, valueUserSelected]);

  return (
    <>
      <Modal
        forceRender
        open={isOpen}
        title={valueUserSelected?.id ? 'Sửa Group Live Show' : 'Thêm Group Live Show'}
        onCancel={handleCancelCreateEditModal}
        width={800}
        getContainer={false}
        footer={false}
      >
        <Form
          name="form"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <label className={styles.labelInput} htmlFor="name">
            Tên Nhóm Live Show
          </label>
          <Form.Item
            name="name"
            rules={[
              REQUIRED,
              {
                max: 100,
                message: 'Không được quá 100 kí tự',
              },
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="Nhập tên" size="large" />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="type">
            Type
          </label>
          <Form.Item rules={[REQUIRED]} name="type" wrapperCol={{ span: 24 }}>
            <Select value={TYPE_LIVE_SHOW.ALL} placeholder="Chọn Type" size="large">
              <Option value={TYPE_LIVE_SHOW.ALL}>ALL</Option>
              <Option value={TYPE_LIVE_SHOW.CAROUSEL}>CAROUSEL</Option>
            </Select>
          </Form.Item>

          <label className={styles.labelInput} htmlFor="date">
            Thời gian
          </label>

          <Form.Item rules={[REQUIRED]} name="date" wrapperCol={{ span: 24 }}>
            <DatePicker />
          </Form.Item>

          <div className="d-flex justify-center">
            <Space size="large">
              <CustomButton
                bgColor="secondary-green"
                text="Submit"
                htmlType="submit"
                loading={loading}
              />
              <CustomButton
                text="Hủy Bỏ"
                bgColor="primary-grey"
                htmlType="button"
                onClick={handleCancelCreateEditModal}
              />
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCreateEdit;
