import React, { useCallback, useEffect, useState } from 'react';
import styles from '../news-manager/styles.module.scss';
import { Form, Input, notification, Select, Space } from 'antd';
import { REQUIRED } from '../../constants/rule-form';
import JoditEditor from 'jodit-react';
import { TYPE_NEW, UPLOAD_TYPE } from '../../constants/common';
import { CHECK_FILE } from '../../utils/rule-form';
import CustomizedUpload from '../../components/customized-upload';
import CustomButton from '../../components/custom-button';
import { values } from 'lodash';
import { editorConfig } from '../news-manager/component/ModalCreateEdit';
import AxiosUtils from '../../utils/axios-utils';
import { API } from '../../service/api-route';

const initialContent = {
  content: '',
  textAppInfo: '',
  textLegalPolicy: '',
  textHp: '',
  linkContact: '',
};

function PolicyManager() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialContent);
  const [update, setUpdate] = useState(false);

  const onFinish = async (values: any) => {
    try {
      const res: any = await AxiosUtils.put(`${API.putRules}`, {
        ...data,
      });
      if (res?.status === 200) {
        setUpdate(!update);
        notification.success({
          message: 'Success',
          description: 'Sửa thành công!',
          duration: 4,
        });
      }
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchRules = async () => {
      setLoading(true);
      try {
        const res: any = await AxiosUtils.get(API.rules);
        console.log(res.data);
        setData((prevState) => {
          return {
            ...prevState,
            textHp: res.data.textHp,
            content: res.data.content,
            textLegalPolicy: res.data.textLegalPolicy,
            textAppInfo: res.data.textAppInfo,
            linkContact: res.data.linkContact,
          };
        });
      } finally {
        setLoading(false);
      }
    };

    fetchRules();
  }, [update]);

  const onBlurContent = useCallback(
    (newContent) => {
      setData((prevState) => {
        return {
          ...prevState,
          content: newContent,
        };
      });
    },
    [setData]
  );

  const onBlurTextHp = useCallback(
    (newContent) => {
      setData((prevState) => {
        return {
          ...prevState,
          textHp: newContent,
        };
      });
    },
    [setData]
  );

  const onBlurTextLegalPolicy = useCallback(
    (newContent) => {
      setData((prevState) => {
        return {
          ...prevState,
          textLegalPolicy: newContent,
        };
      });
    },
    [setData]
  );

  const onBlurTextAppInfo = useCallback(
    (newContent) => {
      setData((prevState) => {
        return {
          ...prevState,
          textAppInfo: newContent,
        };
      });
    },
    [setData]
  );

  const onBlurLinkContact = useCallback(
    (newContent) => {
      setData((prevState) => {
        return {
          ...prevState,
          linkContact: newContent,
        };
      });
    },
    [setData]
  );

  const onChange = useCallback((newContent) => {
    console.log(newContent);
  }, []);

  return (
    <div>
      <Form
        name="form"
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <label className={styles.labelInput} htmlFor="content">
          Quy tắc cộng đồng
        </label>
        <div style={{ maxWidth: editorConfig.width, margin: '0 auto', marginBottom: 10 }}>
          <JoditEditor
            value={data.content}
            config={editorConfig as any}
            onBlur={onBlurContent}
            onChange={onChange}
          />
        </div>

        <label className={styles.labelInput} htmlFor="content">
          Về HP Media
        </label>
        <div style={{ maxWidth: editorConfig.width, margin: '0 auto', marginBottom: 10 }}>
          <JoditEditor
            value={data.textHp}
            config={editorConfig as any}
            onBlur={onBlurTextHp}
            onChange={onChange}
          />
        </div>

        <label className={styles.labelInput} htmlFor="content">
          Pháp lý chính sách
        </label>
        <div style={{ maxWidth: editorConfig.width, margin: '0 auto', marginBottom: 10 }}>
          <JoditEditor
            value={data.textLegalPolicy}
            config={editorConfig as any}
            onBlur={onBlurTextLegalPolicy}
            onChange={onChange}
          />
        </div>

        <label className={styles.labelInput} htmlFor="content">
          Thông tin ứng dụng
        </label>
        <div style={{ maxWidth: editorConfig.width, margin: '0 auto', marginBottom: 10 }}>
          <JoditEditor
            value={data.textAppInfo}
            config={editorConfig as any}
            onBlur={onBlurTextAppInfo}
            onChange={onChange}
          />
        </div>

        <label className={styles.labelInput} htmlFor="linkContact">
          Link Liên Hệ
        </label>
        <div style={{ maxWidth: editorConfig.width, margin: '0 auto', marginBottom: 10 }}>
          <JoditEditor
            value={data.linkContact}
            config={editorConfig as any}
            onBlur={onBlurLinkContact}
            onChange={onChange}
          />
        </div>

        <div className="d-flex justify-center">
          <Space size="large">
            <CustomButton
              bgColor="secondary-green"
              text="Submit"
              htmlType="submit"
              loading={loading}
            />
          </Space>
        </div>
      </Form>
    </div>
  );
}

export default PolicyManager;
