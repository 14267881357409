import { Form, Input, Modal, Select, Space, notification } from 'antd';
import CustomButton from 'components/custom-button';
import { REGEX_USERNAME, ROLE_USER } from 'constants/common';
import { REQUIRED } from 'constants/rule-form';
import { useEffect, useState } from 'react';
import { API } from 'service/api-route';
import { DataPost } from 'types/users';
import AxiosUtils from 'utils/axios-utils';
import styles from '../styles.module.scss';
import { formatData } from '../../../utils/common';
const { Option } = Select;
interface Props {
  isOpen: boolean;
  handleCancelCreateEditModal: () => void;
  onChangeStatusUpdateData: () => void;
  valueUserSelected: any;
}

const ModalCreateEditUser = ({
  isOpen,
  valueUserSelected,
  handleCancelCreateEditModal,
  onChangeStatusUpdateData,
}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: DataPost) => {
    console.log(values);

    setLoading(true);
    if (valueUserSelected?.id) {
      try {
        const res: any = await AxiosUtils.put(
          `${API.editUser}/${valueUserSelected?.id}`,
          formatData(values)
        );
        if (res?.status === 200) {
          handleCancelCreateEditModal();
          onChangeStatusUpdateData();
          notification.success({
            message: 'Success',
            description: 'Sửa thành công!',
            duration: 4,
          });
        }
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res: any = await AxiosUtils.post(API.createUser, values);
        if (res?.status === 201) {
          handleCancelCreateEditModal();
          onChangeStatusUpdateData();
          notification.success({
            message: 'Success',
            description: 'Tạo User thành công!',
            duration: 4,
          });
        }
      } catch (error: any) {
        notification.error({
          message: 'Error',
          description: `${error?.message || 'Some thing wrong !'}`,
          duration: 4,
        });
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      roles: valueUserSelected?.roles || undefined,
      email: valueUserSelected?.email || '',
      nickName: valueUserSelected?.nickName || '',
      username: valueUserSelected?.username || '',
      phoneCode: valueUserSelected?.phoneCode || '',
      phoneNumber: valueUserSelected?.phoneNumber || '',
      isVip: valueUserSelected?.isVip || false,
      isVerifyAccount: valueUserSelected?.isVerifyAccount || false,
      isAccountDisabled: valueUserSelected?.isAccountDisabled || false,
      isOutStanding: valueUserSelected?.isOutStanding || false,
      isHpCompany: valueUserSelected?.isHpCompany || false,
      password: '',
      about: valueUserSelected?.about || '',
    });
  }, [form, valueUserSelected]);

  return (
    <>
      <Modal
        forceRender
        open={isOpen}
        title={valueUserSelected?.id ? 'Sửa User' : 'Tạo mới User'}
        onCancel={handleCancelCreateEditModal}
        width={800}
        getContainer={false}
        footer={false}
      >
        <Form
          name="form"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <label className={styles.labelInput} htmlFor="roles">
            Role
          </label>
          <Form.Item rules={[REQUIRED]} name="roles" wrapperCol={{ span: 24 }}>
            <Select value={ROLE_USER.USER} placeholder="Chọn Role" size="large">
              <Option value={ROLE_USER.ADMIN}>Admin</Option>
              <Option value={ROLE_USER.AGENCY}>Agency</Option>
              <Option value={ROLE_USER.CREATOR}>Creator</Option>
              <Option value={ROLE_USER.MOD}>Mod</Option>
              <Option value={ROLE_USER.USER}>User</Option>
              <Option value={ROLE_USER.STUDIO_CREATOR}>Studio Creator</Option>
            </Select>
          </Form.Item>

          <label className={styles.labelInput} htmlFor="username">
            Username
          </label>
          <Form.Item
            name="username"
            rules={[
              {
                max: 50,
                message: 'Không được quá 50 kí tự',
              },
              {
                pattern: new RegExp(REGEX_USERNAME),
                message: 'Nhập đúng format user',
              },
            ]}
            wrapperCol={{ span: 24 }}
            validateFirst={true}
          >
            <Input autoComplete="off" placeholder="Nhập Username" size="large" />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="nickName">
            Nick Name
          </label>
          <Form.Item
            name="nickName"
            rules={[
              REQUIRED,
              {
                max: 25,
                message: 'Không được quá 25 kí tự',
              },
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="Nhập first name" size="large" />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="email">
            Email
          </label>
          <Form.Item
            name="email"
            rules={[
              REQUIRED,
              {
                type: 'email',
                message: 'Vui lòng nhập đúng định dạng email',
              },
              {
                max: 255,
                message: 'Email không được quá 255 kí tự',
              },
            ]}
            validateFirst={true}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="Nhập email" size="large" />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="phoneCode">
            Phone code
          </label>
          <Form.Item name="phoneCode" wrapperCol={{ span: 24 }}>
            <Input placeholder="Nhập phone code" size="large" />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="phoneNumber">
            Số điện thoại
          </label>
          <Form.Item name="phoneNumber" wrapperCol={{ span: 24 }}>
            <Input placeholder="Nhập SĐT" size="large" />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="about">
            Data Agency
          </label>
          <Form.Item name="about" wrapperCol={{ span: 24 }}>
            <Input placeholder="Nhập Link Data Agency" size="large" />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="isVip">
            Có phải Vip không?
          </label>
          <Form.Item rules={[REQUIRED]} name="isVip" wrapperCol={{ span: 24 }}>
            <Select defaultValue={true} size="large">
              <Option value={true}>Có</Option>
              <Option value={false}>Không</Option>
            </Select>
          </Form.Item>

          <label className={styles.labelInput} htmlFor="isAccountDisabled">
            Disable Account?
          </label>
          <Form.Item rules={[REQUIRED]} name="isAccountDisabled" wrapperCol={{ span: 24 }}>
            <Select defaultValue={true} size="large">
              <Option value={true}>Có</Option>
              <Option value={false}>Không</Option>
            </Select>
          </Form.Item>

          <label className={styles.labelInput} htmlFor="isVerifyAccount">
            Verify Account?
          </label>
          <Form.Item rules={[REQUIRED]} name="isVerifyAccount" wrapperCol={{ span: 24 }}>
            <Select defaultValue={true} size="large">
              <Option value={true}>Có</Option>
              <Option value={false}>Không</Option>
            </Select>
          </Form.Item>
          <label className={styles.labelInput} htmlFor="isOutStanding">
            Có nổi bật hay không?
          </label>
          <Form.Item rules={[REQUIRED]} name="isOutStanding" wrapperCol={{ span: 24 }}>
            <Select defaultValue={true} size="large">
              <Option value={true}>Có</Option>
              <Option value={false}>Không</Option>
            </Select>
          </Form.Item>

          <label className={styles.labelInput} htmlFor="isHpCompany">
            Có thuộc công ty Hp Media quản lý hay không?
          </label>
          <Form.Item rules={[REQUIRED]} name="isHpCompany" wrapperCol={{ span: 24 }}>
            <Select defaultValue={false} size="large">
              <Option value={true}>Có</Option>
              <Option value={false}>Không</Option>
            </Select>
          </Form.Item>

          <label className={styles.labelInput} htmlFor="password">
            Password
          </label>
          <Form.Item
            name="password"
            rules={[
              {
                required: !valueUserSelected?.id,
                message: 'Trường này là bắt buộc.',
              },
              {
                min: 8,
                message: 'Mật khẩu cần ít nhất 8 kí tự',
              },
              {
                max: 15,
                message: 'Mật khẩu cần ít hơn 16 kí tự',
              },
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Input.Password autoComplete="off" size="large" placeholder="Nhập password" />
          </Form.Item>
          <div className="d-flex justify-center">
            <Space size="large">
              <CustomButton
                bgColor="secondary-green"
                text="Submit"
                htmlType="submit"
                loading={loading}
              />
              <CustomButton
                text="Hủy Bỏ"
                bgColor="primary-grey"
                htmlType="button"
                onClick={handleCancelCreateEditModal}
              />
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCreateEditUser;
