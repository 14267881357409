import { Col, DatePicker, Form, Input, Modal, notification, Select, Space } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import CustomButton from 'components/custom-button';
import CustomizedUpload from 'components/customized-upload';
import { TYPE_BANNER, TYPE_JOB, UPLOAD_TYPE } from 'constants/common';
import { REQUIRED } from 'constants/rule-form';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import { CHECK_FILE } from 'utils/rule-form';
import DebounceSelect from '../../../components/debounce-select/select';
import styles from '../styles.module.scss';

const { Option } = Select;
const { TextArea } = Input;

interface DataPost {
  calendarDate?: string;
  title: string;
  content: string;
  type: any;
  group: string;
}

interface Props {
  isOpen: boolean;
  handleCancelCreateEditModal: () => void;
  onChangeStatusUpdateData: () => void;
  valueUserSelected: any;
}

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

// eslint-disable-next-line arrow-body-style
// Can not select days before today and today
const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current.add(1, 'day') < dayjs().endOf('day');
};

console.log('first,', dayjs().endOf('day'));
const disabledDateTime = () => ({
  // disabledHours: () => range(0, 24).splice(0, moment().hours() + 1),
  // disabledMinutes: () => range(0, 60),
  // disabledSeconds: () => [55, 56],
});

const ModalCreateLiveShow = ({
  isOpen,
  valueUserSelected,
  handleCancelCreateEditModal,
  onChangeStatusUpdateData,
}: Props) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [dataUser, setDataUser] = useState<any>([]);
  const type = Form.useWatch('type', { form, preserve: true });
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const fetchGroup = async () => {
      try {
        const res: any = await AxiosUtils.get(API.getListGroup);
        setGroups(res?.data);
      } finally {
        console.log('done');
      }
    };

    fetchGroup();
  }, []);

  const onFinish = async (values: DataPost) => {
    const newValues = { ...values };

    if (newValues.calendarDate) {
      newValues.calendarDate = dayjs(newValues.calendarDate).format();
    }

    newValues.group = newValues.group.toString();

    console.log(newValues, 'new Valiue');
    setLoading(true);
    try {
      await AxiosUtils.post(API.createJob, { ...newValues });
      handleCancelCreateEditModal();
      onChangeStatusUpdateData();
      notification.success({
        message: 'Success',
        description: 'Tạo thành công!',
        duration: 4,
      });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        forceRender
        open={isOpen}
        title={'Thêm tin nhắn tự động'}
        onCancel={handleCancelCreateEditModal}
        width={800}
        getContainer={false}
        footer={false}
      >
        <Form
          name="form"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <label className={styles.labelInput} htmlFor="title">
            Tiêu đề
          </label>
          <Form.Item
            name="title"
            rules={[
              REQUIRED,
              {
                max: 200,
                message: 'Không được quá 200 kí tự',
              },
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="Nhập tiêu đề" />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="content">
            Nội dung
          </label>
          <Form.Item
            rules={[{ required: true, message: 'Nội dung này bắt buộc' }]}
            name="content"
            wrapperCol={{ span: 24 }}
          >
            <Input.TextArea placeholder={'Nội dung'} showCount maxLength={300} />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="group">
            Chọn group để gửi tin nhắn tự động:
          </label>
          <Form.Item rules={[REQUIRED]} name="group" wrapperCol={{ span: 24 }}>
            <Select mode="multiple" value={''} placeholder="Chọn group để gửi tin nhắn tự động">
              {groups?.map((group: any) => {
                return <Option value={`${group.id}`}>{group?.title || 'HP'}</Option>;
              })}
            </Select>
          </Form.Item>

          <label className={styles.labelInput} htmlFor="type">
            Loại tin nhắn tự động:
          </label>
          <Form.Item rules={[REQUIRED]} name="type" wrapperCol={{ span: 24 }}>
            <Select value={TYPE_JOB.NOW} placeholder="Chọn loại phát">
              <Option value={TYPE_JOB.NO}>Không</Option>
              <Option value={TYPE_JOB.CALENDAR}>Đặt lịch</Option>
              <Option value={TYPE_JOB.NOW}>Gửi Ngay</Option>
            </Select>
          </Form.Item>

          {type === TYPE_JOB.CALENDAR && (
            <>
              <label className={styles.labelInput} htmlFor="calendarDate">
                Thời gian Đặt lịch
              </label>
              <Form.Item rules={[REQUIRED]} name="calendarDate" wrapperCol={{ span: 24 }}>
                <DatePicker
                  format="YYYY-MM-DD HH:mm"
                  disabledDate={disabledDate}
                  disabledTime={disabledDateTime}
                  showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm') }}
                />
              </Form.Item>
            </>
          )}

          <div className="d-flex justify-center">
            <Space size="large">
              <CustomButton
                bgColor="secondary-green"
                text="Submit"
                htmlType="submit"
                loading={loading}
              />
              <CustomButton
                text="Hủy Bỏ"
                bgColor="primary-grey"
                htmlType="button"
                onClick={handleCancelCreateEditModal}
              />
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCreateLiveShow;
