import { Button, Modal, notification, Pagination, Select, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';

import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import { formatData, formatedDate } from 'utils/common';
import PageTitle from '../../components/page-title';
import Filter from './component/filter';
import styles from './styles.module.scss';
import ModalCreateEditUser from './component/ModalCreateEditUser';
import CustomButton from 'components/custom-button';
import { PlusOutlined } from '@ant-design/icons';
import { shortenName } from '../../utils/short-name';

const LIMIT = 10;

const initFilter = { page: 1, limit: LIMIT };

const { Option } = Select;
export interface UserFilter {
  limit: number;
  page: number;
  username?: string;
  email?: string;
  startDate?: string;
  endDate?: string;
}

const UserManager = () => {
  const [loading, setLoading] = useState(true);
  const [dataResponse, setDataResponse] = useState<any>({
    items: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState(initFilter as UserFilter);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [statusUpdateData, setStatusUpdateData] = useState(false);
  const [isOpenModalEditAndCreate, setIsOpenModalEditAndCreate] = useState(false);
  const [valueUserSelected, setvalueUserSelected] = useState<any>(null);

  const onFinish = (value: Partial<UserFilter>) => {
    setFilter((preState) => formatData({ ...preState, ...value, page: 1 }));
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const res: any = await AxiosUtils.get(API.users, {
          ...filter,
        });

        const makeData = res?.data?.data?.map((user: any) => {
          return {
            ...user,
            about: user?.profile?.about || '',
          };
        });
        setDataResponse({ items: makeData, totalCount: res?.data?.metadata?.total });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [filter, statusUpdateData]);

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '5%',
      ellipsis: true,
      key: 'id',
    },
    {
      title: 'Tiktok ID',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Nick Name',
      dataIndex: 'nickName',
      key: 'nickName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'roles',
      key: 'roles',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Data Agency',
      dataIndex: 'about',
      key: 'about',
      render: (value) => (
        <Tooltip title={value}>
          <span>{shortenName(value || '', 20) || ''}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Nổi bật',
      dataIndex: 'isOutStanding',
      key: 'isOutStanding',
      render: (value) => <span>{value ? 'Có' : 'Không'}</span>,
    },
    {
      title: 'Vip',
      dataIndex: 'isVip',
      key: 'isVip',
      render: (value) => <span>{value ? 'Có' : 'Không'}</span>,
    },
    {
      title: 'Ngày đăng kí',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => <span>{formatedDate(value)}</span>,
    },
    {
      title: 'Hành động',
      width: 150,
      key: 'action',
      render: (_, record) => (
        <Select
          value="Chọn"
          getPopupContainer={(trigger) => trigger.parentElement}
          dropdownStyle={{ minWidth: 97 }}
        >
          <Option value="action view">
            <div onClick={() => handelOpenCreateEditModal(record)} style={{ textAlign: 'center' }}>
              Sửa
            </div>
          </Option>
        </Select>
      ),
    },
  ];
  const onChangeStatusUpdateData = () => setStatusUpdateData(!statusUpdateData);

  const handleChangePage = (page: number) => {
    setFilter((preState: any) => ({ ...preState, page }));
  };

  const handelOpenDeleteModal = () => {
    setIsModalDeleteOpen(true);
  };

  const handleOkDeleteModal = async () => {
    setLoading(true);
    try {
      await AxiosUtils._delete(API.deleteUsers, { ids: selectedRowKeys });
      onChangeStatusUpdateData();
      setSelectedRowKeys([]);
      notification.success({
        message: 'Success',
        description: 'Xóa thành công!',
        duration: 4,
      });
    } catch (error) {
      console.log(error);
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    } finally {
      setLoading(false);
      setIsModalDeleteOpen(false);
    }
  };

  const handleCancelDeleteModal = () => {
    setIsModalDeleteOpen(false);
  };

  const handelOpenCreateEditModal = (value: any) => {
    setIsOpenModalEditAndCreate(true);
    setvalueUserSelected(value);
  };

  const handleCancelCreateEditModal = () => {
    setIsOpenModalEditAndCreate(false);
    setvalueUserSelected(null);
  };

  return (
    <div>
      <PageTitle title="Quản lí người dùng" />
      <div className={styles.filterContainer}>
        <Filter onSubmit={onFinish} />
      </div>
      <div className="mb-10">
        <CustomButton
          onClick={() => handelOpenCreateEditModal(valueUserSelected)}
          bgColor="primary-green"
          text="Thêm User"
          icon={<PlusOutlined />}
        />
      </div>
      <div className="custom-table custom-form">
        <div className={styles.wrapCounterUser}>
          <Button
            type="primary"
            onClick={handelOpenDeleteModal}
            disabled={!hasSelected}
            loading={loading}
          >
            Xóa
          </Button>
          <span>Số lượng thành viên: {dataResponse?.totalCount || 0}</span>
        </div>

        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Đã chọn ${selectedRowKeys.length} items` : ''}
        </span>

        <Table
          rowSelection={rowSelection}
          columns={columns}
          rowKey="id"
          pagination={false}
          dataSource={dataResponse.items}
          scroll={{ x: 600 }}
          loading={loading}
        />
        {dataResponse.totalCount > LIMIT && (
          <Pagination
            showSizeChanger={false}
            defaultCurrent={LIMIT}
            current={filter.page}
            onChange={handleChangePage}
            showLessItems
            total={dataResponse.totalCount}
            pageSize={LIMIT}
          />
        )}
      </div>

      {isOpenModalEditAndCreate && (
        <ModalCreateEditUser
          isOpen={isOpenModalEditAndCreate}
          handleCancelCreateEditModal={handleCancelCreateEditModal}
          valueUserSelected={valueUserSelected}
          onChangeStatusUpdateData={onChangeStatusUpdateData}
        />
      )}
      <Modal
        title="Xóa User"
        open={isModalDeleteOpen}
        onOk={handleOkDeleteModal}
        onCancel={handleCancelDeleteModal}
      >
        <p>Bạn có thực sự muốn xóa {selectedRowKeys.length} users</p>
      </Modal>
    </div>
  );
};

export default UserManager;
