import React, { useEffect, useState } from 'react';
import AxiosUtils from '../../utils/axios-utils';
import { API } from '../../service/api-route';
import { Col, Form, Input, notification, Row, Select, Space, TimePicker } from 'antd';
import { REQUIRED } from '../../constants/rule-form';
import CustomButton from '../../components/custom-button';
import { ROLE_USER } from '../../constants/common';
import dayjs from 'dayjs';
import styles from '../news-manager/styles.module.scss';

type DataPost = {
  usingVoice: string;
  roleSalary: string;
  timeSendNotificationPk: any;
  timeSendSalary: any;
};

const ROLES = [
  { id: 1, value: ROLE_USER.USER },
  { id: 2, value: ROLE_USER.CREATOR },
  { id: 3, value: ROLE_USER.MOD },
  { id: 4, value: ROLE_USER.AGENCY },
  { id: 5, value: ROLE_USER.ADMIN },
];

const { Option } = Select;

function SettingManager() {
  const [setting, setSetting] = useState({
    usingVoice: '',
    timeSendNotificationPk: '',
  });
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  console.log(setting, 'setting');

  useEffect(() => {
    const fetchSetting = async () => {
      try {
        const res: any = await AxiosUtils.get(API.settings);
        form.setFieldsValue({
          usingVoice: res.data.usingVoice.split(','),
          roleSalary: res.data.roleSalary.split(','),
          timeSendNotificationPk: dayjs(res.data.timeSendNotificationPk, 'HH:mm'),
          timeSendSalary: dayjs(res.data.timeSendSalary, 'HH:mm'),
          titleDataLive: res.data.titleDataLive,
          contentDataLive: res.data.contentDataLive,
          timeSendDataLiveInMonth: res.data.timeSendDataLiveInMonth,
          titleCheckDataLive: res.data.titleCheckDataLive,
          contentCheckDataLive: res.data.contentCheckDataLive,
          timeSendCheckDataLiveInMonth: res.data.timeSendCheckDataLiveInMonth,
          titlePayment: res.data.titlePayment,
          contentPayment: res.data.contentPayment,
          timeSendPaymentInMonth: res.data.timeSendPaymentInMonth,
        });
        setSetting(res?.data);
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      }
    };

    fetchSetting();
  }, []);

  const onFinish = async (values: DataPost) => {
    if (values.timeSendNotificationPk) {
      values.timeSendNotificationPk = dayjs(values.timeSendNotificationPk).format('HH:mm');
    }

    if (values.timeSendSalary) {
      values.timeSendSalary = dayjs(values.timeSendSalary).format('HH:mm');
    }

    values.usingVoice = values.usingVoice.toString();
    values.roleSalary = values.roleSalary.toString();

    try {
      await AxiosUtils.put(API.settings, values);
      notification.success({
        message: 'Success',
        description: 'Setting thành công!',
        duration: 4,
      });
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    } finally {
      setLoading(false);
    }

    console.log(values);
  };

  return (
    <div>
      <Form
        name="form"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <label htmlFor="">Setting role có thê sử dụng voice</label>
        <Row className="d-flex justify-center">
          <Col span={24}>
            <Form.Item rules={[REQUIRED]} name="usingVoice" wrapperCol={{ span: 12 }}>
              <Select size={'large'} mode="multiple" placeholder="Chọn role để sử dụng voice">
                {ROLES?.map((role: any) => {
                  return (
                    <>
                      <Option key={role.id} value={role.value}>
                        {role?.value}
                      </Option>
                    </>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <label htmlFor="">Setting nhóm nhận thông báo lương</label>
        <Row className="d-flex justify-center">
          <Col span={24}>
            <Form.Item rules={[REQUIRED]} name="roleSalary" wrapperCol={{ span: 12 }}>
              <Select size={'large'} mode="multiple" placeholder="Chọn role để gửi thông báo">
                {ROLES?.map((role: any) => {
                  return (
                    <>
                      <Option key={role.id} value={role.value}>
                        {role?.value}
                      </Option>
                    </>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <label htmlFor="">Setting time gửi thông báo PK cho user hàng ngày</label>

        <Row className="d-flex justify-center">
          <Col span={24}>
            <Form.Item rules={[REQUIRED]} name="timeSendNotificationPk" wrapperCol={{ span: 12 }}>
              <TimePicker format={'HH:mm'} showSecond={false} />
            </Form.Item>
          </Col>
        </Row>

        <label htmlFor="">Setting time gửi thông báo lương hàng tháng</label>

        <Row className="d-flex justify-center">
          <Col span={24}>
            <Form.Item rules={[REQUIRED]} name="timeSendSalary" wrapperCol={{ span: 12 }}>
              <TimePicker format={'HH:mm'} showSecond={false} />
            </Form.Item>
          </Col>
        </Row>

        <hr />
        <br />
        <br />

        <label className={styles.labelInput} htmlFor="title">
          Tiêu đề Data Live
        </label>
        <Row className="d-flex justify-center">
          <Col span={24}>
            <Form.Item
              name="titleDataLive"
              rules={[
                REQUIRED,
                {
                  max: 200,
                  message: 'Không được quá 100 kí tự',
                },
              ]}
              wrapperCol={{ span: 12 }}
            >
              <Input placeholder="Nhập tiêu đề" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <label className={styles.labelInput} htmlFor="title">
          Content Data Live
        </label>
        <Row className="d-flex justify-center">
          <Col span={24}>
            <Form.Item
              name="contentDataLive"
              rules={[
                REQUIRED,
                {
                  max: 200,
                  message: 'Không được quá 100 kí tự',
                },
              ]}
              wrapperCol={{ span: 12 }}
            >
              <Input placeholder="Nhập tiêu đề" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <label className={styles.labelInput} htmlFor="timeSendDataLiveInMonth">
          Ngày gửi Data Live trong tháng
        </label>
        <Row className="d-flex justify-center">
          <Col span={24}>
            <Form.Item
              name="timeSendDataLiveInMonth"
              rules={[
                REQUIRED,
                {
                  max: 200,
                  message: 'Không được quá 100 kí tự',
                },
              ]}
              wrapperCol={{ span: 12 }}
            >
              <Input placeholder="Nhập tiêu đề" size="large" />
            </Form.Item>
          </Col>
        </Row>

        <hr />
        <br />
        <br />

        <label className={styles.labelInput} htmlFor="title">
          Tiêu đề Check Data Live
        </label>
        <Row className="d-flex justify-center">
          <Col span={24}>
            <Form.Item
              name="titleCheckDataLive"
              rules={[
                REQUIRED,
                {
                  max: 200,
                  message: 'Không được quá 100 kí tự',
                },
              ]}
              wrapperCol={{ span: 12 }}
            >
              <Input placeholder="Nhập tiêu đề" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <label className={styles.labelInput} htmlFor="title">
          Content Check Data Live
        </label>
        <Row className="d-flex justify-center">
          <Col span={24}>
            <Form.Item
              name="contentCheckDataLive"
              rules={[
                REQUIRED,
                {
                  max: 200,
                  message: 'Không được quá 100 kí tự',
                },
              ]}
              wrapperCol={{ span: 12 }}
            >
              <Input placeholder="Nhập tiêu đề" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <label className={styles.labelInput} htmlFor="timeSendDataLiveInMonth">
          Ngày gửi Check Data Live trong tháng
        </label>
        <Row className="d-flex justify-center">
          <Col span={24}>
            <Form.Item
              name="timeSendCheckDataLiveInMonth"
              rules={[
                REQUIRED,
                {
                  max: 200,
                  message: 'Không được quá 100 kí tự',
                },
              ]}
              wrapperCol={{ span: 12 }}
            >
              <Input placeholder="Nhập tiêu đề" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <hr />
        <br />
        <br />

        <label className={styles.labelInput} htmlFor="title">
          Tiêu đề Thanh toán lương
        </label>
        <Row className="d-flex justify-center">
          <Col span={24}>
            <Form.Item
              name="titlePayment"
              rules={[
                REQUIRED,
                {
                  max: 200,
                  message: 'Không được quá 100 kí tự',
                },
              ]}
              wrapperCol={{ span: 12 }}
            >
              <Input placeholder="Nhập tiêu đề" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <label className={styles.labelInput} htmlFor="title">
          Content Thanh toán lương
        </label>
        <Row className="d-flex justify-center">
          <Col span={24}>
            <Form.Item
              name="contentPayment"
              rules={[
                REQUIRED,
                {
                  max: 200,
                  message: 'Không được quá 100 kí tự',
                },
              ]}
              wrapperCol={{ span: 12 }}
            >
              <Input placeholder="Nhập tiêu đề" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <label className={styles.labelInput} htmlFor="timeSendDataLiveInMonth">
          Ngày gửi thanh toán lương trong tháng
        </label>
        <Row className="d-flex justify-center">
          <Col span={24}>
            <Form.Item
              name="timeSendPaymentInMonth"
              rules={[
                REQUIRED,
                {
                  max: 200,
                  message: 'Không được quá 100 kí tự',
                },
              ]}
              wrapperCol={{ span: 12 }}
            >
              <Input placeholder="Nhập tiêu đề" size="large" />
            </Form.Item>
          </Col>
        </Row>

        <div className="d-flex">
          <Space size="large">
            <CustomButton
              bgColor="secondary-green"
              text="Submit"
              htmlType="submit"
              loading={loading}
            />
          </Space>
        </div>
      </Form>
    </div>
  );
}

export default SettingManager;
