import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import CustomButton from 'components/custom-button';
import CustomizedUpload from 'components/customized-upload';
import { REGEX_USERNAME, ROLE_USER, UPLOAD_TYPE } from 'constants/common';
import { REQUIRED } from 'constants/rule-form';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import { CHECK_FILE } from 'utils/rule-form';
import DebounceSelect from '../../../components/debounce-select/select';
import styles from '../styles.module.scss';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import moment from 'moment';
import { SERVER_FILE_ENDPOINT } from '../../../constants/envs';

const { Option } = Select;
interface DataPost {
  startDate: string;
  link?: string;
  name: string;
  image: any;
  user: any;
}

interface Props {
  isOpen: boolean;
  handleCancelCreateEditModal: () => void;
  onChangeStatusUpdateData: () => void;
  valueUserSelected: any;
}

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

// eslint-disable-next-line arrow-body-style
// Can not select days before today and today
const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current.add(1, 'day') < dayjs().endOf('day');
};

console.log('first,', dayjs().endOf('day'));
const disabledDateTime = () => ({
  // disabledHours: () => range(0, 24).splice(0, moment().hours() + 1),
  // disabledMinutes: () => range(0, 60),
  // disabledSeconds: () => [55, 56],
});

const ModalCreateLiveShow = ({
  isOpen,
  valueUserSelected,
  handleCancelCreateEditModal,
  onChangeStatusUpdateData,
}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [listGroups, setListGroup] = useState<any>([]);
  const [checkIdol, setCheckIdol] = useState(false);

  useEffect(() => {
    const fetchGroupList = async () => {
      setLoading(true);
      try {
        const res: any = await AxiosUtils.get(API.groupShowList);
        setListGroup(res?.data);
      } finally {
        setLoading(false);
      }
    };

    fetchGroupList();
  }, []);

  useEffect(() => {
    if (valueUserSelected) {
      let idolId: any = {};
      if (valueUserSelected.tiktokIdol) {
        setCheckIdol(true);
      }

      let groupId: any = {};

      if (valueUserSelected?.user) {
        idolId.key = valueUserSelected?.user?.id;
        idolId.value = valueUserSelected?.user?.id;
        idolId.label = valueUserSelected?.user?.username;
      }

      if (valueUserSelected.group) {
        groupId.key = valueUserSelected?.group?.id;
        groupId.label = valueUserSelected?.group.name;
        groupId.value = valueUserSelected?.group.id;
      }

      form.setFieldsValue({
        startDate: dayjs(valueUserSelected?.startDate),
        user: idolId,
        tiktokIdol: valueUserSelected?.tiktokIdol || '',
        link: valueUserSelected?.link || '',
        groupId: groupId,
        image: valueUserSelected?.image
          ? {
              fileKey: valueUserSelected?.image,
              fileUrl: `${SERVER_FILE_ENDPOINT}/${valueUserSelected.image}`,
            }
          : null,
      });
    }
  }, [form, valueUserSelected]);

  const filterOptions = useCallback(async (search: string) => {
    const response: any = await AxiosUtils.get(`${API.searchUser}?query=${search}`);
    return response.data.map((item: any) => ({
      id: item.id,
      label: item.username,
      value: item.id,
    }));
  }, []);

  const onFinish = async (values: DataPost) => {
    const newValues = { ...values };

    newValues.image = values?.image?.fileKey;

    newValues.startDate = dayjs(values.startDate).format();

    setLoading(true);

    if (valueUserSelected?.id) {
      try {
        await AxiosUtils.patch(`${API.createShow}/${valueUserSelected.id}`, {
          ...newValues,
          userId: values?.user?.value || null,
        });
        handleCancelCreateEditModal();
        onChangeStatusUpdateData();
        notification.success({
          message: 'Success',
          description: 'Tạo thành công!',
          duration: 4,
        });
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await AxiosUtils.post(API.createShow, {
          ...newValues,
          userId: values?.user?.value || null,
        });
        handleCancelCreateEditModal();
        onChangeStatusUpdateData();
        notification.success({
          message: 'Success',
          description: 'Sửa thành công!',
          duration: 4,
        });
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        forceRender
        open={isOpen}
        title={'Thêm Live Show'}
        onCancel={handleCancelCreateEditModal}
        width={800}
        getContainer={false}
        footer={false}
      >
        <Form
          name="form"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          {/*<label className={styles.labelInput} htmlFor="name">*/}
          {/*  Tên Live Show*/}
          {/*</label>*/}
          {/*<Form.Item*/}
          {/*  name="name"*/}
          {/*  rules={[*/}
          {/*    REQUIRED,*/}
          {/*    {*/}
          {/*      max: 200,*/}
          {/*      message: 'Không được quá 200 kí tự',*/}
          {/*    },*/}
          {/*  ]}*/}
          {/*  wrapperCol={{ span: 24 }}*/}
          {/*>*/}
          {/*  <Input placeholder="Nhập tên Live Show" />*/}
          {/*</Form.Item>*/}

          <label className={styles.labelInput} htmlFor="groupId">
            Nhóm Live Show
          </label>
          <Form.Item rules={[REQUIRED]} name="groupId" wrapperCol={{ span: 24 }}>
            <Select placeholder="Chọn nhóm live show" size="large">
              {listGroups.map((item: any) => (
                <Option key={item?.id} value={item?.id}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <label className={styles.labelInput} htmlFor="name">
            Link
          </label>
          <Form.Item name="link" wrapperCol={{ span: 24 }}>
            <Input size={'large'} placeholder="Nhập link live show" />
          </Form.Item>

          <label htmlFor="">Chọn Tiktok ID:</label>
          <Row gutter={[16, 0]} className="d-flex justify-center">
            <Col span={15}>
              {checkIdol ? (
                <Form.Item
                  name="tiktokIdol"
                  rules={[
                    REQUIRED,
                    {
                      max: 100,
                      message: 'Không được quá 100 kí tự',
                    },
                    {
                      pattern: new RegExp(REGEX_USERNAME),
                      message: 'Nhập đúng format user',
                    },
                  ]}
                  wrapperCol={{ span: 24 }}
                  validateFirst={true}
                >
                  <Input placeholder="Nhập idol " size="large" />
                </Form.Item>
              ) : (
                <Form.Item name="user" wrapperCol={{ span: 24 }} rules={[REQUIRED]}>
                  <DebounceSelect placeholder="Chọn Idol PK2" fetchOptions={filterOptions} />
                </Form.Item>
              )}
            </Col>
            <Col span={9}>
              <Checkbox
                checked={checkIdol}
                onChange={(e: CheckboxChangeEvent) => {
                  e.target.checked
                    ? form.setFieldsValue({
                        user: undefined,
                      })
                    : form.setFieldsValue({
                        tiktokIdol: '',
                      });
                  setCheckIdol(e.target.checked);
                }}
              >
                Không tìm thấy idol
              </Checkbox>
            </Col>
          </Row>
          {/*<Col span={24}>*/}
          {/*  <Form.Item name="user" wrapperCol={{ span: 24 }} rules={[REQUIRED]}>*/}
          {/*    <DebounceSelect placeholder="Chọn Idol PK1" fetchOptions={filterOptions} />*/}
          {/*  </Form.Item>*/}
          {/*</Col>*/}

          <label className={styles.labelInput} htmlFor="startDate">
            Thời gian PK
          </label>
          <Form.Item rules={[REQUIRED]} name="startDate" wrapperCol={{ span: 24 }}>
            <DatePicker
              format="YYYY-MM-DD HH:mm"
              disabledDate={disabledDate}
              disabledTime={disabledDateTime}
              showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="image">
            Ảnh
          </label>
          <Form.Item name="image" wrapperCol={{ span: 24 }} rules={[CHECK_FILE]}>
            <CustomizedUpload type={UPLOAD_TYPE.IMAGE} maxFileSize={20} />
          </Form.Item>

          <div className="d-flex justify-center">
            <Space size="large">
              <CustomButton
                bgColor="secondary-green"
                text="Submit"
                htmlType="submit"
                loading={loading}
              />
              <CustomButton
                text="Hủy Bỏ"
                bgColor="primary-grey"
                htmlType="button"
                onClick={handleCancelCreateEditModal}
              />
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCreateLiveShow;
