import React from 'react';
import styles from './style.module.scss';

interface Props {
  title: string;
  style?: any;
}

const PageTitle = ({ title, style }: Props) => {
  return (
    <div className={styles.pageTitle} style={style}>
      {title}
    </div>
  );
};

export default PageTitle;
