import { REQUIRED_MESSAGE } from 'constants/common';
import { FileUploadTypes } from 'types/common';

const REQUIRED = { required: true, message: REQUIRED_MESSAGE };
const CHECK_FILE = {
  validator: (_: any, value: FileUploadTypes) => {
    if (value?.error) {
      return Promise.reject(new Error(value?.error));
    }
    if (value?.fileUrl) {
      return Promise.resolve();
    }

    return Promise.reject(new Error(REQUIRED_MESSAGE));
  }
};



export {
  REQUIRED,
  CHECK_FILE,
};
