import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  data: any;
  isLoading: boolean;
  error: {
    code?: number;
    message?: string;
    path?: string;
    timestamp?: string;
  };
  isTokenExpired?: boolean;
}
const initialState: InitialState = {
  data: null,
  isLoading: false,
  error: {},
  isTokenExpired: false,
};

const sessionSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    getAuthLoading: (state) => {
      state.isLoading = true;
    },
    getAuthSuccess: (state, action?) => {
      state.data = action?.payload;
      state.isLoading = false;
    },
    getAuthFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    removeAuth: (state) => {
      state.isLoading = false;
      state.error = {};
    },
    changeTokenExpired: (state) => {
      state.data = null;
      state.error = {};
      state.isLoading = false;
      state.isTokenExpired = true;
    },
  },
});

export const { getAuthLoading, getAuthSuccess, getAuthFailure, removeAuth, changeTokenExpired } =
  sessionSlice.actions;

export default sessionSlice.reducer;
