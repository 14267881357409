import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  notification,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RangePickerProps } from 'antd/es/date-picker';
import CustomButton from 'components/custom-button';
import CustomizedUpload from 'components/customized-upload';
import { REGEX_USERNAME, ROLE_USER, UPLOAD_TYPE } from 'constants/common';
import { REQUIRED } from 'constants/rule-form';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import { CHECK_FILE } from 'utils/rule-form';
import DebounceSelect from '../../../components/debounce-select/select';
import styles from '../styles.module.scss';
import moment from 'moment';
const { Option } = Select;
interface DataPost {
  setting1: string;
  setting2: string;
  startDate: string;
  pk1?: any;
  pk2?: any;
  pk1Info?: any;
  pk2Info?: any;
  name: string;
  // image?: any;
}

interface Props {
  isOpen: boolean;
  handleCancelCreateEditModal: () => void;
  onChangeStatusUpdateData: () => void;
  valueUserSelected: any;
}

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

// eslint-disable-next-line arrow-body-style
// Can not select days before today and today
const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current.add(1, 'day') < dayjs().endOf('day');
};

console.log('first,', dayjs().endOf('day'));
const disabledDateTime = () => ({
  // disabledHours: () => range(0, 24).splice(0, moment().hours() + 1),
  // disabledMinutes: () => range(0, 60),
  // disabledSeconds: () => [55, 56],
});

const ModalCreateEdit = ({
  isOpen,
  valueUserSelected,
  handleCancelCreateEditModal,
  onChangeStatusUpdateData,
}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [dataUser, setDataUser] = useState<any>([]);
  const [dataCountry, setDataCountry] = useState<any>([]);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);

  console.log(valueUserSelected, 'valueUserSelected');

  useEffect(() => {
    if (valueUserSelected) {
      let pk1Info: any = {};
      let pk2Info: any = {};

      if (valueUserSelected?.pk1Info) {
        pk1Info.key = valueUserSelected?.pk1Info?.id;
        pk1Info.value = valueUserSelected?.pk1Info?.id;
        pk1Info.label = valueUserSelected?.pk1Info?.username;
      }

      if (valueUserSelected.pk1) {
        setChecked1(true);
      }

      if (valueUserSelected.pk2) {
        setChecked2(true);
      }

      if (valueUserSelected?.pk2Info) {
        pk2Info.key = valueUserSelected?.pk2Info?.id;
        pk2Info.value = valueUserSelected?.pk2Info?.id;
        pk2Info.label = valueUserSelected?.pk2Info?.username;
      }

      console.log(pk1Info, pk2Info, 'data set');

      form.setFieldsValue({
        name: valueUserSelected?.name,
        startDate: dayjs(valueUserSelected?.startDate),
        setting1: valueUserSelected?.setting1?.id,
        setting2: valueUserSelected?.setting2?.id,
        pk1Info: pk1Info,
        pk1: valueUserSelected?.pk1 || '',
        pk2: valueUserSelected?.pk2 || '',
        pk2Info: pk2Info,
      });
    }
  }, [form, valueUserSelected]);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const res: any = await AxiosUtils.get(API.users, {
          page: 1,
          limit: 10000,
        });
        setDataUser(res?.data?.data);
      } finally {
        setLoading(false);
      }
    };

    const fetchCountry = async () => {
      setLoading(true);
      try {
        const res: any = await AxiosUtils.get(API.listCountry, {
          page: 1,
          limit: 100,
        });
        setDataCountry(res?.data?.data);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
    fetchCountry();
  }, []);

  const filterOptions = useCallback(async (search: string) => {
    const response: any = await AxiosUtils.get(`${API.searchUser}?query=${search}`);
    return response.data.map((item: any) => ({
      id: item.id,
      label: item.username,
      value: item.id,
    }));
  }, []);

  const onFinish = async (values: DataPost) => {
    const newValues = { ...values };
    if (newValues.pk1Info) {
      newValues.pk1Info = values?.pk1Info?.value;
    }

    if (newValues.pk2Info) {
      newValues.pk2Info = values?.pk2Info?.value;
    }
    //newValues.image = values?.image?.fileKey;

    newValues.startDate = dayjs(values.startDate).format();

    setLoading(true);

    if (valueUserSelected?.id) {
      try {
        await AxiosUtils.put(`${API.updatePK}/${valueUserSelected.id}`, newValues);
        handleCancelCreateEditModal();
        onChangeStatusUpdateData();
        notification.success({
          message: 'Success',
          description: 'Sửa thành công!',
          duration: 4,
        });
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await AxiosUtils.post(API.updatePK, newValues);
        handleCancelCreateEditModal();
        onChangeStatusUpdateData();
        notification.success({
          message: 'Success',
          description: 'Tạo thành công!',
          duration: 4,
        });
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {}, [form, checked1]);

  useEffect(() => {}, [form, checked2]);

  return (
    <>
      <Modal
        forceRender
        open={isOpen}
        title={valueUserSelected?.id ? 'Sửa trận PK' : 'Thêm trận PK'}
        onCancel={handleCancelCreateEditModal}
        width={800}
        getContainer={false}
        maskClosable={false}
        footer={false}
      >
        <Form
          name="form"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <label className={styles.labelInput} htmlFor="name">
            Tên Agency Quản lý
          </label>
          <Form.Item
            name="name"
            rules={[
              REQUIRED,
              {
                max: 200,
                message: 'Không được quá 200 kí tự',
              },
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="Nhập tên PK" size="large" />
          </Form.Item>

          <label htmlFor="">TIKTOK ID 1</label>

          <Row gutter={[16, 0]} className="d-flex justify-center">
            <Col span={15}>
              {checked1 ? (
                <Form.Item
                  name="pk1"
                  rules={[
                    REQUIRED,
                    {
                      max: 100,
                      message: 'Không được quá 100 kí tự',
                    },
                    {
                      pattern: new RegExp(REGEX_USERNAME),
                      message: 'Nhập đúng format user',
                    },
                  ]}
                  wrapperCol={{ span: 24 }}
                  validateFirst={true}
                >
                  <Input placeholder="Nhập idol PK1" size="large" />
                </Form.Item>
              ) : (
                <Form.Item name="pk1Info" wrapperCol={{ span: 24 }} rules={[REQUIRED]}>
                  <DebounceSelect placeholder="Chọn Idol PK1" fetchOptions={filterOptions} />
                </Form.Item>
              )}
            </Col>
            <Col span={9}>
              <Checkbox
                checked={checked1}
                onChange={(e: CheckboxChangeEvent) => {
                  e.target.checked
                    ? form.setFieldsValue({
                        pk1Info: null,
                      })
                    : form.setFieldsValue({
                        pk1: '',
                      });
                  setChecked1(e.target.checked);
                }}
              >
                Không tìm thấy idol
              </Checkbox>
            </Col>
          </Row>

          <label className={styles.labelInput} htmlFor="setting1">
            QUỐC GIA 1
          </label>
          <Form.Item rules={[REQUIRED]} name="setting1" wrapperCol={{ span: 24 }}>
            <Select placeholder="Chọn Country Idol1" size="large">
              {dataCountry.map((item: any) => (
                <Option key={item?.id} value={item?.id}>
                  {item?.country}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <label htmlFor="">TIKTOK ID 2</label>

          <Row gutter={[16, 0]} className="d-flex justify-center">
            <Col span={15}>
              {checked2 ? (
                <Form.Item
                  name="pk2"
                  rules={[
                    REQUIRED,
                    {
                      max: 100,
                      message: 'Không được quá 100 kí tự',
                    },
                    {
                      pattern: new RegExp(REGEX_USERNAME),
                      message: 'Nhập đúng format user',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          getFieldValue('pk1') === value ||
                          getFieldValue('pk1Info')?.label === value
                        ) {
                          return Promise.reject(new Error('Không thể nhập 2 user giống nhau!'));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  wrapperCol={{ span: 24 }}
                  validateFirst={true}
                >
                  <Input placeholder="Nhập idol PK2" size="large" />
                </Form.Item>
              ) : (
                <Form.Item
                  name="pk2Info"
                  wrapperCol={{ span: 24 }}
                  rules={[
                    REQUIRED,
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          getFieldValue('pk1') === value?.label ||
                          getFieldValue('pk1Info')?.label === value?.label
                        ) {
                          return Promise.reject(new Error('Không thể nhập 2 user giống nhau!'));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <DebounceSelect placeholder="Chọn Idol PK2" fetchOptions={filterOptions} />
                </Form.Item>
              )}
            </Col>
            <Col span={9}>
              <Checkbox
                checked={checked2}
                onChange={(e: CheckboxChangeEvent) => {
                  e.target.checked
                    ? form.setFieldsValue({
                        pk2Info: null,
                      })
                    : form.setFieldsValue({
                        pk2: '',
                      });
                  setChecked2(e.target.checked);
                }}
              >
                Không tìm thấy idol
              </Checkbox>
            </Col>
          </Row>

          <label className={styles.labelInput} htmlFor="setting2">
            QUỐC GIA 2
          </label>
          <Form.Item rules={[REQUIRED]} name="setting2" wrapperCol={{ span: 24 }}>
            <Select placeholder="Chọn country Idol2" size="large">
              {dataCountry.map((item: any) => (
                <Option key={item?.id} value={item?.id}>
                  {item?.country}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/*<label className={styles.labelInput} htmlFor="setting2">*/}
          {/*  Người tạo PK*/}
          {/*</label>*/}

          {/*<Form.Item rules={[REQUIRED]} name="type" wrapperCol={{ span: 24 }}>*/}
          {/*  <Select value={ROLE_USER.USER} placeholder="Chọn type" size="large">*/}
          {/*    <Option value={ROLE_USER.AGENCY}>Agency</Option>*/}
          {/*    <Option value={ROLE_USER.CREATOR}>Creator</Option>*/}
          {/*  </Select>*/}
          {/*</Form.Item>*/}

          <label className={styles.labelInput} htmlFor="startDate">
            Thời gian PK
          </label>
          <Form.Item rules={[REQUIRED]} name="startDate" wrapperCol={{ span: 24 }}>
            <DatePicker
              format="YYYY-MM-DD HH:mm"
              disabledDate={disabledDate}
              disabledTime={disabledDateTime}
              showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>

          {/*<label className={styles.labelInput} htmlFor="image">*/}
          {/*  Ảnh*/}
          {/*</label>*/}
          {/*<Form.Item name="image" wrapperCol={{ span: 24 }} rules={[]}>*/}
          {/*  <CustomizedUpload type={UPLOAD_TYPE.IMAGE} maxFileSize={20} />*/}
          {/*</Form.Item>*/}

          <div className="d-flex justify-center">
            <Space size="large">
              <CustomButton
                bgColor="secondary-green"
                text="Submit"
                htmlType="submit"
                loading={loading}
              />
              <CustomButton
                text="Hủy Bỏ"
                bgColor="primary-grey"
                htmlType="button"
                onClick={handleCancelCreateEditModal}
              />
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCreateEdit;
