import { getAuthLoading, getAuthSuccess, getAuthFailure } from './reducers';
import AxiosUtils from 'utils/axios-utils';
import { API } from 'service/api-route';
import { AppDispatch } from '../../types/redux';
import { setSession } from '../../utils/session';
import { paths } from 'routes/routes';

export type Account = {
  email?: string;
  password?: string;
};

export const login = (account: Account, navigate: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(getAuthLoading());
    const res: any = await AxiosUtils.post(API.login, account);
    if (res?.status === 200) {
      dispatch(getAuthSuccess({ accessToken: res.data }));
      setSession({ accessToken: res.data });
      navigate(paths.home);
    }

  } catch (error: any) {
    dispatch(getAuthFailure(error));
  }
};
