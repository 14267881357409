import Header from './components/header';
import Menu from './components/menu';
import Profile from './components/profile';
import styles from './styles.module.scss';

interface Props {
  children: any;
}
const Layout = ({ children }: Props) => {
  return (
    <div className={styles.layout}>
      <div className={styles.sidebar}>
        <Menu />
        <Profile />
      </div>
      <div className={styles.boxMainContent}>
        <Header />
        <div className={styles.children}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
