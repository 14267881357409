import HomePage from 'pages/home';
import LoginPage from 'pages/login';
import NotFound from 'pages/not-found';
import ResetPassword from 'pages/reset-password';
import SendMailPW from 'pages/reset-password/send-mail';
import UserManagement from 'pages/user-manager';
import { RouteObject } from 'types/route';
import NewsManager from 'pages/news-manager';
import CountryManager from 'pages/country-manager';
import RevenueManager from 'pages/revenue-manager';
import SoundsManager from 'pages/sounds-manager';
import PKManager from 'pages/pk-manager';
import RequestIdolManager from 'pages/request-idol-manager';
import BannerManager from 'pages/banners-manager';
import MenuManager from 'pages/menus-manager';
import AdsManager from 'pages/ads-manager';
import LiveShowManager from '../pages/liveshows-manager';
import JobNotificationManager from '../pages/job-notification';
import StoreManager from '../pages/store-manager';
import OutStandingManager from '../pages/outstanding-manager';
import PickIdolManager from '../pages/pick-idol-manager';
import PolicyManager from '../pages/policy-manager';
import GroupLiveShowManager from '../pages/group-live-show-manager';
import NotificationManager from '../pages/notification-manager';
import DomesticManager from '../pages/domestic-manager';
import AddUserDomesticManager from '../pages/add-user-domestic-manager';
import SettingManager from '../pages/setting-manager';
import GroupDomesticDetailManager from '../pages/group-domestic-detail-manager';
import VoteManager from '../pages/vote-manager';
import AddUserVoteManager from '../pages/add-user-vote-manager';
import UserJoinVoteManager from '../pages/user-join-vote-manager';

export const paths = {
  home: '/',
  login: '/login',
  notFound: '*',
  chat: 'chat',
  sendMailForgotPw: '/send-mail-forgot-password',
  resetPassword: '/forgot-password',
  accountManagement: '/users-management',
  news: '/news-management',
  revenue: '/revenue-management',
  sounds: '/sounds-management',
  pk: '/pk-management',
  liveShow: '/live-management',
  groupShow: '/show-management',
  job: '/job-management',
  store: '/store-management',
  outstanding: '/outstanding-management',
  requestIdol: '/request-idol-management',
  settingCountry: '/setting-country',
  menus: '/menus-management',
  banners: 'banners-management',
  settingAds: '/setting-ads',
  pick: '/pick-idol',
  policy: 'policy-manager',
  notification: 'notification-management',
  domestic: 'domestic-management',
  addUserToDomestic: 'domestic-user',
  settings: 'setting-management',
  groupDetail: '/group/:id/management',
  vote: '/vote-management',
  addUserVote: '/add-user-vote',
  listUserJoinVote: '/votes/:id/list-user',
};

const mainRoutes: RouteObject[] = [
  { path: paths.notFound, component: NotFound },
  { path: paths.login, component: LoginPage, isPublish: true },
  { path: paths.sendMailForgotPw, component: SendMailPW, isPublish: true },
  { path: paths.resetPassword, component: ResetPassword, isPublish: true },
  { path: paths.home, component: HomePage },
  { path: paths.accountManagement, component: UserManagement },
  { path: paths.news, component: NewsManager },
  { path: paths.revenue, component: RevenueManager },
  { path: paths.sounds, component: SoundsManager },
  { path: paths.pk, component: PKManager },
  { path: paths.settingCountry, component: CountryManager },
  { path: paths.requestIdol, component: RequestIdolManager },
  { path: paths.banners, component: BannerManager },
  { path: paths.menus, component: MenuManager },
  { path: paths.settingAds, component: AdsManager },
  { path: paths.liveShow, component: LiveShowManager },
  { path: paths.groupShow, component: GroupLiveShowManager },
  { path: paths.job, component: JobNotificationManager },
  { path: paths.store, component: StoreManager },
  { path: paths.outstanding, component: OutStandingManager },
  { path: paths.pick, component: PickIdolManager },
  { path: paths.policy, component: PolicyManager },
  { path: paths.notification, component: NotificationManager },
  { path: paths.domestic, component: DomesticManager },
  { path: paths.addUserToDomestic, component: AddUserDomesticManager },
  { path: paths.settings, component: SettingManager },
  { path: paths.groupDetail, component: GroupDomesticDetailManager },
  { path: paths.vote, component: VoteManager },
  { path: paths.addUserVote, component: AddUserVoteManager },
  { path: paths.listUserJoinVote, component: UserJoinVoteManager },
];

export { mainRoutes };
