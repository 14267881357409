import React from 'react';
import { useNavigate } from 'react-router-dom';
import { removeAuth } from 'redux/auth/reducers';
import styles from './styles.module.scss';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { paths } from 'routes/routes';
import { useAppDisPatch } from 'utils/common';
import axios from 'axios';
import AxiosUtils from 'utils/axios-utils';
import { API } from 'service/api-route';

const Profile = () => {
  const dispatch = useAppDisPatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    // await AxiosUtils.post(API.logout)
    localStorage.clear();
    dispatch(removeAuth());
    navigate(paths.login);
  };

  const MENU_ITEM = [{ label: 'Đăng xuất', onClick: handleLogout, icon: faArrowRightFromBracket }];

  return (
    <div className={styles.profile}>
      {MENU_ITEM.map((item: any, index: number) => (
        <div key={index} className={styles.profileMenu} onClick={item.onClick}>
          <FontAwesomeIcon icon={item.icon} size="lg" color={'white'} />
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default Profile;
