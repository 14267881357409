import React from 'react';
import PageTitle from '../../components/page-title';

const HomePage = () => {
  return (
    <div>
      <PageTitle title="Dashboard" />
      <div>
        <p>Dashboard Dashboard Dashboard Dashboard Dashboard Dashboard</p>
      </div>
    </div>
  );
};

export default HomePage;
