import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Button, Form, Modal, notification, Pagination, Select, Table, Tooltip } from 'antd';
import AxiosUtils from '../../utils/axios-utils';
import { API } from '../../service/api-route';
import { ColumnsType } from 'antd/lib/table';
import { shortenName } from '../../utils/short-name';
import { formatedDate } from '../../utils/common';
import { UserFilter } from '../news-manager';
import { useNavigate, useParams } from 'react-router-dom';
import { SERVER_FILE_ENDPOINT } from '../../constants/envs';
const LIMIT = 10;

const initFilter = { page: 1, limit: LIMIT };

function UserJoinVoteManager() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dataResponse, setDataResponse] = useState<any>({
    items: [],
    totalCount: 0,
  });

  const [infoGroup, setInfoGroup] = useState<any>(null);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [filter, setFilter] = useState(initFilter as UserFilter);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [statusUpdateData, setStatusUpdateData] = useState(false);
  const handleChangePage = (page: number) => {
    setFilter((preState: any) => ({ ...preState, page }));
  };

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const res: any = await AxiosUtils.get(`${API.listUser}/${id}/users`);
        setDataResponse({ items: res?.data });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [filter, statusUpdateData]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '5%',
      ellipsis: true,
      key: 'id',
    },
    {
      title: 'Tiktok ID',
      key: 'username',
      render: (_, record) => (
        <Tooltip title={record?.user?.username}>
          <span>{record?.user?.username}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Email',
      key: 'email',
      render: (_, record) => (
        <Tooltip title={record?.user?.email}>
          <span>{record?.user?.email}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      render: (value) => (
        <Tooltip title={value}>
          <span>{value}</span>
        </Tooltip>
      ),
    },

    {
      title: 'Ngày Tham gia',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => <span>{formatedDate(value)}</span>,
    },
  ];

  const handelOpenDeleteModal = () => {
    setIsModalDeleteOpen(true);
  };

  const onChangeStatusUpdateData = () => setStatusUpdateData(!statusUpdateData);

  const handleCancelDeleteModal = () => {
    setIsModalDeleteOpen(false);
  };

  const handleOkDeleteModal = async () => {
    setLoading(true);
    try {
      await AxiosUtils._delete(API.deleteUserJoinVote, { ids: selectedRowKeys });
      onChangeStatusUpdateData();
      notification.success({
        message: 'Success',
        description: 'Xóa thành công!',
        duration: 4,
      });
      setSelectedRowKeys([]);
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    } finally {
      setLoading(false);
      setIsModalDeleteOpen(false);
    }
  };

  return (
    <div>
      <div onClick={() => navigate('/vote-management')} className={styles.wrapBack}>
        Quay lại
      </div>

      <div className={styles.wrapMg} />
      <div className="custom-table custom-form">
        <Button
          type="primary"
          onClick={handelOpenDeleteModal}
          disabled={!hasSelected}
          loading={loading}
        >
          Xóa
        </Button>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Đã chọn ${selectedRowKeys.length} người tham gia` : ''}
        </span>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          rowKey="id"
          pagination={false}
          dataSource={dataResponse.items}
          scroll={{ x: 600 }}
          loading={loading}
        />
        <Modal
          title="Xóa Người tham gia"
          open={isModalDeleteOpen}
          onOk={handleOkDeleteModal}
          onCancel={handleCancelDeleteModal}
        >
          <p>Bạn có thực sự muốn xóa {selectedRowKeys.length} người tham gia.</p>
        </Modal>
      </div>
    </div>
  );
}

export default UserJoinVoteManager;
