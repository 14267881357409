import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import CustomButton from 'components/custom-button';
import moment from 'moment';
import { ROLE_USER } from 'constants/common';

const { Option } = Select;

export const getMonthCurrent = () => {
  const month = moment().month();

  if (month < 10) {
    return `0${month + 1}`;
  }
  return month + 1;
};

const initialValues = {
  type: '',
  date: `${moment().year()}-${getMonthCurrent()}`,
};

const Filter: React.FC<any> = (props) => {
  const [form] = Form.useForm();
  const { onSubmit } = props;

  const Months = useMemo(() => {
    return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map(
      (item, index) => {
        return { value: `${moment().year()}-${item}`, label: index + 1 };
      }
    );
  }, []);

  console.log(Months);

  const onClearFilter = () => {
    form.resetFields();

    onSubmit({ date: `${moment().year()}-${getMonthCurrent()}` }, true);
  };

  const onFormSubmit = (value: any) => {
    if (!value.type) {
      delete value.type;
    }
    onSubmit({ ...value });
  };

  return (
    <Form form={form} name="register" onFinish={onFormSubmit} initialValues={initialValues}>
      <Row gutter={[24, 0]} className="custom-form">
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="type" label="Type" wrapperCol={{ span: 24 }}>
            <Select placeholder="Chọn type" size="large">
              <Option value={ROLE_USER.AGENCY}>Agency</Option>
              <Option value={ROLE_USER.CREATOR}>Creator</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="date" label="Tháng" wrapperCol={{ span: 24 }}>
            <Select placeholder="Chọn tháng" size="large">
              {Months.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}{' '}
            </Select>
          </Form.Item>
        </Col>

        <Col style={{ paddingTop: 25 }}>
          <Form.Item className="text-right mt-10">
            <CustomButton
              bgColor="secondary-green"
              htmlType="submit"
              text="Tìm kiếm"
              icon={<SearchOutlined />}
            />
            <CustomButton
              text="Clear"
              bgColor="primary-grey"
              icon={<CloseOutlined />}
              className="ml-5"
              onClick={onClearFilter}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
