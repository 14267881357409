import { DatePicker, Form, Input, Modal, notification, Space } from 'antd';
import CustomButton from 'components/custom-button';
import { UPLOAD_TYPE } from 'constants/common';
import { useEffect, useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import styles from '../style.module.scss';
import CustomizedUpload from 'components/customized-upload';
import { SERVER_FILE_ENDPOINT } from 'constants/envs';
import { CHECK_FILE } from 'utils/rule-form';
import { REQUIRED } from '../../../constants/rule-form';
import dayjs from 'dayjs';
import moment from 'moment';

interface DataPost {
  link?: string;
  image: any;
}

interface Props {
  isOpen: boolean;
  handleCancelCreateEditModal: () => void;
  onChangeStatusUpdateData: () => void;
  valueUserSelected: any;
}

const ModalCreateEdit = ({
  isOpen,
  valueUserSelected,
  handleCancelCreateEditModal,
  onChangeStatusUpdateData,
}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values: DataPost) => {
    values.image = values?.image?.fileKey;
    setLoading(true);
    if (valueUserSelected?.id) {
      try {
        const res: any = await AxiosUtils.patch(
          `${API.outstandingUpdate}/${valueUserSelected?.id}`,
          {
            ...values,
          }
        );
        if (res?.status === 200) {
          handleCancelCreateEditModal();
          onChangeStatusUpdateData();
          notification.success({
            message: 'Success',
            description: 'Sửa thành công!',
            duration: 4,
          });
        }
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res: any = await AxiosUtils.post(API.outstandingUpdate, { ...values });
        if (res?.status === 201) {
          handleCancelCreateEditModal();
          onChangeStatusUpdateData();
          notification.success({
            message: 'Success',
            description: 'Tạo thành công!',
            duration: 4,
          });
        }
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (valueUserSelected) {
      form.setFieldsValue({
        link: valueUserSelected?.link || '',
        date: dayjs(valueUserSelected?.date),
        image: valueUserSelected?.image
          ? {
              fileKey: valueUserSelected?.image,
              fileUrl: `${SERVER_FILE_ENDPOINT}/${valueUserSelected.image}`,
            }
          : null,
      });
    }
  }, [form, valueUserSelected]);

  return (
    <>
      <Modal
        forceRender
        open={isOpen}
        title={valueUserSelected?.id ? 'Sửa tin nổi bật' : 'Thêm tin nổi bật'}
        onCancel={handleCancelCreateEditModal}
        width={800}
        getContainer={false}
        footer={false}
      >
        <Form
          name="form"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <label className={styles.labelInput} htmlFor="link">
            Link redirect
          </label>
          <Form.Item
            name="link"
            rules={[
              {
                max: 200,
                message: 'Không được quá 200 kí tự',
              },
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="Nhập link" size="large" />
          </Form.Item>
          <label className={styles.labelInput} htmlFor="date">
            Thời gian
          </label>
          <Form.Item rules={[REQUIRED]} name="date" wrapperCol={{ span: 24 }}>
            <DatePicker />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="image">
            Ảnh
          </label>
          <Form.Item name="image" wrapperCol={{ span: 24 }} rules={[CHECK_FILE]}>
            <CustomizedUpload type={UPLOAD_TYPE.IMAGE} maxFileSize={5} />
          </Form.Item>

          <div className="d-flex justify-center">
            <Space size="large">
              <CustomButton
                bgColor="secondary-green"
                text="Submit"
                htmlType="submit"
                loading={loading}
              />
              <CustomButton
                text="Hủy Bỏ"
                bgColor="primary-grey"
                htmlType="button"
                onClick={handleCancelCreateEditModal}
              />
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCreateEdit;
