import { Button, Modal, Pagination, Select, Table, Tooltip, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import { formatedDate } from 'utils/common';
import PageTitle from '../../components/page-title';
import Filter from './component/filter';
import styles from './styles.module.scss';
import CustomButton from 'components/custom-button';
import { PlusOutlined } from '@ant-design/icons';
import { SERVER_FILE_ENDPOINT } from 'constants/envs';
import { shortenName } from 'utils/short-name';
import ModalCreateEdit from './component/ModalCreateEdit';
import moment from 'moment';

const LIMIT = 10;

const initFilter = { page: 1, limit: LIMIT };

const { Option } = Select;
export interface PKFilter {
  limit: number;
  page: number;
  startDate?: string;
  endDate?: string;
}

const PKManager = () => {
  const [loading, setLoading] = useState(true);
  const [dataResponse, setDataResponse] = useState<any>({
    items: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState(initFilter as PKFilter);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [statusUpdateData, setStatusUpdateData] = useState(false);
  const [isOpenModalEditAndCreate, setIsOpenModalEditAndCreate] = useState(false);
  const [valueUserSelected, setvalueUserSelected] = useState<any>(null);

  const onFinish = (value: Partial<PKFilter>) => {
    setFilter((preState) => ({ ...preState, ...value, page: 1 }));
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    const fetchPks = async () => {
      setLoading(true);
      try {
        const res: any = await AxiosUtils.get(API.pks, {
          ...filter,
        });
        setDataResponse({ items: res?.data?.data, totalCount: res?.data?.metadata?.total });
      } finally {
        setLoading(false);
      }
    };

    fetchPks();
  }, [filter, statusUpdateData]);

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '5%',
      ellipsis: true,
      key: 'id',
    },
    {
      title: 'Tên Agency Quản lý',
      dataIndex: 'name',
      key: 'name',
      render: (value) => (
        <Tooltip title={value}>
          <span>{shortenName(value || '', 60) || ''}</span>
        </Tooltip>
      ),
    },
    // {
    //   title: 'Ảnh',
    //   dataIndex: 'image',
    //   key: 'image',
    //   render: (value) => (
    //     <img
    //       width={136}
    //       height={72}
    //       style={{ objectFit: 'cover' }}
    //       src={`${SERVER_FILE_ENDPOINT}/${value}`}
    //       alt=""
    //     />
    //   ),
    // },
    {
      title: 'PK1',
      key: 'pk1',
      render: (_, record) => <span>{record?.pk1Info?.username || record?.pk1}</span>,
    },

    {
      title: 'Quốc gia 1',
      key: 'Country',
      render: (_, record) => (
        <div>
          <div>{record?.setting1?.country}</div>
          <img
            src={`${SERVER_FILE_ENDPOINT}/${record?.setting1?.img}`}
            width={90}
            height={60}
            style={{ objectFit: 'cover' }}
            alt="img-country"
          />
        </div>
      ),
    },

    {
      title: 'PK2',
      key: 'pk2',
      render: (_, record) => <span>{record?.pk2Info?.username || record?.pk2}</span>,
    },

    {
      title: 'Quốc gia 2',
      key: 'Country 2',
      render: (_, record) => (
        <div>
          <div>{record?.setting2?.country}</div>
          <img
            src={`${SERVER_FILE_ENDPOINT}/${record?.setting2?.img}`}
            width={90}
            height={60}
            style={{ objectFit: 'cover' }}
            alt="img-country"
          />
        </div>
      ),
    },

    {
      title: 'Thời gian bắt đầu',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (value) => (
        <span>
          {formatedDate(value)} - {moment(value).format('LT')}
        </span>
      ),
    },

    // {
    //   title: 'Ngày kết thúc',
    //   dataIndex: 'endDate',
    //   key: 'endDate',
    //   render: (value) => (
    //     <span>
    //       {formatedDate(value)} - {moment(value).format('LT')}
    //     </span>
    //   ),
    // },
    {
      title: 'Hành động',
      width: 150,
      key: 'action',
      render: (_, record) => (
        <Select
          value="Chọn"
          getPopupContainer={(trigger) => trigger.parentElement}
          dropdownStyle={{ minWidth: 97 }}
        >
          <Option value="action view">
            <div onClick={() => handelOpenCreateEditModal(record)} style={{ textAlign: 'center' }}>
              Sửa
            </div>
          </Option>
        </Select>
      ),
    },
  ];
  const onChangeStatusUpdateData = () => setStatusUpdateData(!statusUpdateData);

  const handleChangePage = (page: number) => {
    setFilter((preState: any) => ({ ...preState, page }));
  };

  const handelOpenDeleteModal = () => {
    setIsModalDeleteOpen(true);
  };

  const handleOkDeleteModal = async () => {
    setLoading(true);
    try {
      await AxiosUtils._delete(API.deletePK, { ids: selectedRowKeys });
      onChangeStatusUpdateData();
      notification.success({
        message: 'Success',
        description: 'Xóa thành công!',
        duration: 4,
      });
      setSelectedRowKeys([]);
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    } finally {
      setLoading(false);
      setIsModalDeleteOpen(false);
    }
  };

  const handleCancelDeleteModal = () => {
    setIsModalDeleteOpen(false);
  };

  const handelOpenCreateEditModal = (value: any) => {
    setIsOpenModalEditAndCreate(true);
    setvalueUserSelected(value);
  };

  const handleCancelCreateEditModal = () => {
    setIsOpenModalEditAndCreate(false);
    setvalueUserSelected(null);
  };

  return (
    <div>
      <PageTitle title="Quản lí PK" />
      <div className={styles.filterContainer}>
        <Filter onSubmit={onFinish} />
      </div>
      <div className="mb-10">
        <CustomButton
          onClick={() => handelOpenCreateEditModal(valueUserSelected)}
          bgColor="primary-green"
          text="Thêm PK"
          icon={<PlusOutlined />}
        />
      </div>
      <div className="custom-table custom-form">
        <Button
          type="primary"
          onClick={handelOpenDeleteModal}
          disabled={!hasSelected}
          loading={loading}
        >
          Xóa
        </Button>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Đã chọn ${selectedRowKeys.length} PK` : ''}
        </span>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          rowKey="id"
          pagination={false}
          dataSource={dataResponse.items}
          scroll={{ x: 600 }}
          loading={loading}
        />
        {dataResponse.totalCount > LIMIT && (
          <Pagination
            showSizeChanger={false}
            defaultCurrent={LIMIT}
            current={filter.page}
            onChange={handleChangePage}
            showLessItems
            total={dataResponse.totalCount}
            pageSize={LIMIT}
          />
        )}
      </div>

      {isOpenModalEditAndCreate && (
        <ModalCreateEdit
          isOpen={isOpenModalEditAndCreate}
          handleCancelCreateEditModal={handleCancelCreateEditModal}
          valueUserSelected={valueUserSelected}
          onChangeStatusUpdateData={onChangeStatusUpdateData}
        />
      )}
      <Modal
        title="Xóa User"
        open={isModalDeleteOpen}
        onOk={handleOkDeleteModal}
        onCancel={handleCancelDeleteModal}
      >
        <p>Bạn có thực sự muốn xóa {selectedRowKeys.length} PK.</p>
      </Modal>
    </div>
  );
};

export default PKManager;
