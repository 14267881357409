import { Col, Form, Input, Row, Select } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import React from 'react';
import CustomButton from 'components/custom-button';
import { ROLE_USER, TYPE_SOUND } from '../../../constants/common';

type FilterProps = {
  onSubmit: (value: any) => void;
};

const initialValues = {
  name: '',
  type: '',
};
const { Option } = Select;

const Filter: React.FC<any> = (props) => {
  const [form] = Form.useForm();
  const { onSubmit } = props;

  const onClearFilter = () => {
    form.resetFields();
    onSubmit({ ...form.getFieldsValue() });
  };

  const onFormSubmit = (value: typeof initialValues) => {
    const { ...rest } = value;

    onSubmit({ ...rest });
  };

  return (
    <Form form={form} name="register" onFinish={onFormSubmit} initialValues={initialValues}>
      <Row gutter={[24, 0]} className="custom-form">
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="name" label="Tên âm thanh">
            <Input placeholder="Tên âm thanh" />
          </Form.Item>
        </Col>
        <Col md={{ span: 6 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="type" label="Loại Âm thanh" wrapperCol={{ span: 24 }}>
            <Select placeholder="Chọn role" size="large">
              <Option value={TYPE_SOUND.SOUND_EFFECT}>Hiệu ứng</Option>
              <Option value={TYPE_SOUND.MUSIC}>Âm thanh</Option>
              <Option value={TYPE_SOUND.PAY_THE_BET}>Trả kèo</Option>
              <Option value={TYPE_SOUND.SYNTHETIC}>Tổng hợp</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col style={{ paddingTop: 25 }}>
          <Form.Item className="text-right mt-10">
            <CustomButton
              bgColor="secondary-green"
              htmlType="submit"
              text="Tìm kiếm"
              icon={<SearchOutlined />}
            />
            <CustomButton
              text="Clear"
              bgColor="primary-grey"
              icon={<CloseOutlined />}
              className="ml-5"
              onClick={onClearFilter}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
