import { Form, Input, Modal, Select, Space, notification } from 'antd';
import CustomButton from 'components/custom-button';
import { TYPE_NEW, UPLOAD_TYPE } from 'constants/common';
import { REQUIRED } from 'constants/rule-form';
import { useCallback, useEffect, useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import styles from '../styles.module.scss';
import CustomizedUpload from 'components/customized-upload';
import { SERVER_FILE_ENDPOINT } from 'constants/envs';
import { CHECK_FILE } from 'utils/rule-form';
import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from 'jodit-react';
const { Option } = Select;
interface DataPost {
  title: string;
  image: any;
  description: string;
  content: string;
  type: string;
}

const copyStringToClipboard = function (str: any) {
  let el = document.createElement('textarea') as any;
  el.value = str;
  el.setAttribute('readonly', '');
  // el?.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const buttons = [
  'undo',
  'redo',
  '|',
  'bold',
  'strikethrough',
  'underline',
  'italic',
  '|',
  'superscript',
  'subscript',
  '|',
  'align',
  '|',
  'ul',
  'ol',
  'outdent',
  'indent',
  '|',
  'font',
  'fontsize',
  'brush',
  'paragraph',
  '|',
  'image',
  'link',
  'table',
  '|',
  'hr',
  'eraser',
  'copyformat',
  '|',
  'fullsize',
  'selectall',
  'print',
  '|',
  'source',
  '|',
  {
    name: 'insertMergeField',
    tooltip: 'Insert Merge Field',
    iconURL: 'images/merge.png',
  },
  {
    name: 'copyContent',
    tooltip: 'Copy HTML to Clipboard',
    iconURL: 'images/copy.png',
    exec: function (editor: any) {
      let html = editor.value;
      copyStringToClipboard(html);
    },
  },
];

export const editorConfig = {
  readonly: false,
  toolbar: true,
  spellcheck: true,
  language: 'vi',
  autoFocus: true,
  cursorAfterAutofocus: 'end',
  toolbarButtonSize: 'medium',
  toolbarAdaptive: false,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: false,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  defaultActionOnPaste: 'insert_clear_html',
  buttons: buttons,
  uploader: {
    insertImageAsBase64URI: false,
  },
  width: 800,
  minHeight: 400,
};

interface Props {
  isOpen: boolean;
  handleCancelCreateEditModal: () => void;
  onChangeStatusUpdateData: () => void;
  valueUserSelected: any;
}

const initialContent = ``;

const ModalCreateEdit = ({
  isOpen,
  valueUserSelected,
  handleCancelCreateEditModal,
  onChangeStatusUpdateData,
}: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialContent);
  const onFinish = async (values: DataPost) => {
    values.image = values?.image?.fileKey;
    setLoading(true);
    if (valueUserSelected?.id) {
      try {
        const res: any = await AxiosUtils.put(`${API.updateNew}/${valueUserSelected?.id}`, {
          ...values,
          content: data,
        });
        if (res?.status === 200) {
          handleCancelCreateEditModal();
          onChangeStatusUpdateData();
          notification.success({
            message: 'Success',
            description: 'Sửa thành công!',
            duration: 4,
          });
        }
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res: any = await AxiosUtils.post(API.updateNew, { ...values, content: data });
        if (res?.status === 201) {
          handleCancelCreateEditModal();
          onChangeStatusUpdateData();
          notification.success({
            message: 'Success',
            description: 'Tạo thành công!',
            duration: 4,
          });
        }
      } catch (error) {
        notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      title: valueUserSelected?.title || '',
      image: valueUserSelected?.image
        ? {
            fileKey: valueUserSelected?.image,
            fileUrl: `${SERVER_FILE_ENDPOINT}/${valueUserSelected.image}`,
          }
        : null,
      description: valueUserSelected?.description || '',
      content: valueUserSelected?.content || '',
      type: valueUserSelected?.type || undefined,
    });
    valueUserSelected?.content && setData(valueUserSelected.content);
  }, [form, valueUserSelected]);

  const onBlur = useCallback(
    (newContent) => {
      setData(newContent);
    },
    [setData]
  );

  const onChange = useCallback((newContent) => {
    console.log(newContent);
  }, []);

  return (
    <>
      <Modal
        forceRender
        open={isOpen}
        title={valueUserSelected?.id ? 'Sửa tin tức' : 'Thêm tin tức'}
        onCancel={handleCancelCreateEditModal}
        width={800}
        getContainer={false}
        footer={false}
      >
        <Form
          name="form"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <label className={styles.labelInput} htmlFor="title">
            Tiêu đề
          </label>
          <Form.Item
            name="title"
            rules={[
              REQUIRED,
              {
                max: 200,
                message: 'Không được quá 100 kí tự',
              },
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="Nhập tiêu đề" size="large" />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="content">
            Nội dung
          </label>
          <div style={{ maxWidth: editorConfig.width, margin: '0 auto', marginBottom: 10 }}>
            <JoditEditor
              value={data}
              config={editorConfig as any}
              onBlur={onBlur}
              onChange={onChange}
            />
          </div>

          <label className={styles.labelInput} htmlFor="link">
            Link redirect
          </label>
          <Form.Item
            name="link"
            rules={[
              {
                max: 200,
                message: 'Không được quá 200 kí tự',
              },
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="Nhập link" size="large" />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="description">
            Mô tả
          </label>
          <Form.Item
            name="description"
            rules={[
              REQUIRED,
              {
                max: 750,
                message: 'Không được quá 750 kí tự',
              },
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="Nhập mô tả" size="large" />
          </Form.Item>

          <label className={styles.labelInput} htmlFor="type">
            Trạng thái
          </label>
          <Form.Item rules={[REQUIRED]} name="type" wrapperCol={{ span: 24 }}>
            <Select value={TYPE_NEW.PUBLIC} placeholder="Chọn trạng thái" size="large">
              <Option value={TYPE_NEW.PUBLIC}>Public</Option>
              <Option value={TYPE_NEW.PRIVATE}>Private</Option>
            </Select>
          </Form.Item>

          <label className={styles.labelInput} htmlFor="image">
            Ảnh
          </label>
          <Form.Item name="image" wrapperCol={{ span: 24 }} rules={[CHECK_FILE]}>
            <CustomizedUpload type={UPLOAD_TYPE.IMAGE} maxFileSize={5} />
          </Form.Item>

          <div className="d-flex justify-center">
            <Space size="large">
              <CustomButton
                bgColor="secondary-green"
                text="Submit"
                htmlType="submit"
                loading={loading}
              />
              <CustomButton
                text="Hủy Bỏ"
                bgColor="primary-grey"
                htmlType="button"
                onClick={handleCancelCreateEditModal}
              />
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCreateEdit;
