import { useState } from 'react';
import PageTitle from '../../components/page-title';
import CustomButton from 'components/custom-button';
import { PlusOutlined } from '@ant-design/icons';
import ModalCreateEdit from './component/ModalPickIdol';

const PickIdolManager = () => {
  const [isOpenModalEditAndCreate, setIsOpenModalEditAndCreate] = useState(false);
  const [valueUserSelected, setvalueUserSelected] = useState<any>(null);

  const handelOpenCreateEditModal = (value: any) => {
    setIsOpenModalEditAndCreate(true);
    setvalueUserSelected(value);
  };

  return (
    <div>
      <PageTitle title="Quản lí Pick Idol" />
      <div className="mb-10">
        <CustomButton
          onClick={() => handelOpenCreateEditModal(valueUserSelected)}
          bgColor="primary-green"
          text="Pick Idol"
          icon={<PlusOutlined />}
        />
        {isOpenModalEditAndCreate && (
          <ModalCreateEdit
            isOpen={isOpenModalEditAndCreate}
            handleCancelCreateEditModal={() => setIsOpenModalEditAndCreate(false)}
            valueUserSelected={valueUserSelected}
            onChangeStatusUpdateData={() => console.log('test')}
          />
        )}
      </div>
    </div>
  );
};

export default PickIdolManager;
