import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../types/redux';
import AxiosUtils from './axios-utils';
import moment from 'moment';

export const useAppDisPatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


export const isEmptyObject = (obj: object) => {
  return Object.keys(obj).length === 0;
};

export const handleUploadFile = async (file: any): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);
  const apiResponse = (await AxiosUtils.postS3('/common/s3/upload', formData)) as any;
  return apiResponse;
};


export function formatData(obj: any) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName];
    }
  }
  return obj
}


export const onChangeValueInputNumber = (value: string | number, maxValue: number, action: any) => {
  if (value === '.') return action('')
  let number = value
    .toString()
    .replace(/[^0-9.]/g, '')
    .replace(/(\..*?)\..*/g, '$1')
  if (Number(number) >= maxValue) {
    number = number.slice(0, -1)
  }
  action(number)
}



export const formatedDate = (date: string) => {
  return moment(date).format('DD/MM/YYYY');
};
