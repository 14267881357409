export const API = {
  // auth
  login: 'auth/login',
  logout: 'auth/logout',
  getUserInfo: 'auth/status',

  changePassword: '/change-password',
  sendEmailForgotPW: 'admin/send-mail-forgot-password',
  resetPassword: 'admin/reset-password',

  //upload-file
  uploadFile: '/image/storages',

  // user management
  users: '/users',
  createUser: '/users/create',
  deleteUsers: '/users/delete',
  editUser: '/users',
  checkUsers: '/users/check',
  searchUser: '/users/search',
  searchAgency: '/users/search-agency',
  agencyUpdate: '/users/agency-update',
  getListGroup: '/users/groups',

  //groups

  groups: '/groups/admin',
  createGroup: '/groups',
  searchGroup: '/groups/search',
  addUserToGroup: '/groups/add-user',
  userDomestic: '/users',

  //news
  news: '/news',
  updateNew: '/news',
  deleteNew: '/news/delete',

  //vote
  votes: '/votes/admin',
  listUser: '/votes',
  voteCreate: '/votes',
  updateVote: '/votes',
  deleteVote: '/votes/delete',
  voteSearch: '/votes/search',
  userJoinVote: '/user-joins/event',
  deleteUserJoinVote: '/user-joins/delete',

  //rules
  rules: '/rules',
  putRules: '/rules',

  //store

  stores: '/stores/admin',
  createStore: '/stores',
  updateStore: '/stores',
  deleteStore: '/stores/delete',

  //outstanding

  outstanding: '/outstanding/admin',
  outstandingCreate: '/outstanding',
  outstandingUpdate: '/outstanding',
  outstandingDelete: '/outstanding/delete',

  //country
  listCountry: '/country/settings',
  updateCountry: '/country/settings',
  deleteCountry: '/country/settings/delete',

  ///revenues
  revenues: '/revenues',
  revenueDelete: '/revenues/delete',

  //settings

  settings: '/settings',

  //sounds
  sounds: '/sounds/admin',
  createSounds: '/sounds/create',
  editSound: 'sounds',
  deleteSounds: '/sounds/delete',

  //pk
  pks: '/pks',
  updatePK: '/pks',
  deletePK: '/pks/delete',

  //convesations

  createConversation: 'conversations',

  //liveshows
  liveShow: '/live-shows',
  jobNotification: '/job-notifications',
  deleteShow: '/live-shows/delete',
  createShow: '/live-shows',
  //requestIdol
  requestIdol: '/idols/requests',
  updateRequestIdol: '/idols/requests',

  //job

  createJob: '/job-notifications',
  deleteJob: '/job-notifications/delete',

  //notification

  pushNotification: '/notifications/all',
  adminNotification: '/notifications/admin',
  deleteNotification: '/notifications/delete',

  //banners
  banners: '/banners',
  updateBanners: '/banners',
  deleteBanners: '/banners/delete',

  //menus
  menus: '/menus/admin',
  updateMenu: '/menus',
  deleteMenu: '/menus/delete',

  //ads
  ads: '/ads',
  listAds: '/image-ads',
  postAds: '/image-ads',
  deleteAds: '/image-ads/delete',

  //group live show
  groupShow: '/group-shows',
  groupShowList: '/group-shows/list',
  groupShowDel: '/group-shows/delete',
};
