import { notification, Pagination, Select, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { API } from 'service/api-route';
import AxiosUtils from 'utils/axios-utils';
import { formatedDate } from 'utils/common';
import PageTitle from '../../components/page-title';
import CustomButton from 'components/custom-button';
import { PlusOutlined } from '@ant-design/icons';
import { SERVER_FILE_ENDPOINT } from 'constants/envs';
import { shortenName } from 'utils/short-name';
import ModalCreateEdit from './components/ModalCreatAndEdit';
import { useNavigate, useRoutes } from 'react-router-dom';

const LIMIT = 10;

const initFilter = { page: 1, limit: LIMIT };

const { Option } = Select;
export interface UserFilter {
  limit: number;
  page: number;
  username?: string;
  email?: string;
  startDate?: string;
  endDate?: string;
}

const DomesticManger = () => {
  const [loading, setLoading] = useState(true);
  const [dataResponse, setDataResponse] = useState<any>({
    items: [],
    totalCount: 0,
  });
  const navigate = useNavigate();
  const [filter, setFilter] = useState(initFilter as UserFilter);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [statusUpdateData, setStatusUpdateData] = useState(false);
  const [isOpenModalEditAndCreate, setIsOpenModalEditAndCreate] = useState(false);
  const [valueUserSelected, setvalueUserSelected] = useState<any>(null);

  const onFinish = (value: Partial<UserFilter>) => {
    setFilter((preState) => ({ ...preState, ...value, page: 1 }));
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const res: any = await AxiosUtils.get(API.groups, {
          ...filter,
        });
        setDataResponse({ items: res?.data?.data, totalCount: res?.data?.metadata?.total });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [filter, statusUpdateData]);

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '5%',
      ellipsis: true,
      key: 'id',
    },
    {
      title: 'Tên gia tộc',
      dataIndex: 'title',
      key: 'title',
      render: (value) => (
        <Tooltip title={value}>
          <span>{shortenName(value || '', 60) || ''}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Ảnh',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (value) => (
        <img
          width={136}
          height={72}
          style={{ objectFit: 'cover' }}
          src={`${SERVER_FILE_ENDPOINT}/${value}`}
          alt=""
        />
      ),
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      render: (value) => (
        <Tooltip title={value}>
          <span>{shortenName(value || '', 60) || ''}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'type',
      key: 'type',
    },

    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => <span>{formatedDate(value)}</span>,
    },
    {
      title: 'Action',
      width: 150,
      key: 'action',
      render: (_, record) => (
        <Select
          value="Chọn"
          getPopupContainer={(trigger) => trigger.parentElement}
          dropdownStyle={{ minWidth: 97 }}
        >
          <Option value="action view">
            <div onClick={() => handelOpenCreateEditModal(record)} style={{ textAlign: 'center' }}>
              Sửa
            </div>
          </Option>
          <Option value="action view">
            <div
              onClick={() => navigate(`/group/${record.id}/management`)}
              style={{ textAlign: 'center' }}
            >
              Chi tiết
            </div>
          </Option>
        </Select>
      ),
    },
  ];
  const onChangeStatusUpdateData = () => setStatusUpdateData(!statusUpdateData);

  const handleChangePage = (page: number) => {
    setFilter((preState: any) => ({ ...preState, page }));
  };

  const handelOpenDeleteModal = () => {
    setIsModalDeleteOpen(true);
  };

  const handleOkDeleteModal = async () => {
    setLoading(true);
    try {
      await AxiosUtils._delete(API.deleteNew, { ids: selectedRowKeys });
      onChangeStatusUpdateData();
      notification.success({
        message: 'Success',
        description: 'Xóa thành công!',
        duration: 4,
      });
      setSelectedRowKeys([]);
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    } finally {
      setLoading(false);
      setIsModalDeleteOpen(false);
    }
  };

  const handleCancelDeleteModal = () => {
    setIsModalDeleteOpen(false);
  };

  const handelOpenCreateEditModal = (value: any) => {
    setIsOpenModalEditAndCreate(true);
    setvalueUserSelected(value);
  };

  const handleCancelCreateEditModal = () => {
    setIsOpenModalEditAndCreate(false);
    setvalueUserSelected(null);
  };

  return (
    <div>
      <PageTitle title="Quản lí gia tộc" />
      <div className="mb-10">
        <CustomButton
          onClick={() => handelOpenCreateEditModal(valueUserSelected)}
          bgColor="primary-green"
          text="Thêm gia tộc"
          icon={<PlusOutlined />}
        />
      </div>
      <div className="custom-table custom-form">
        <Table
          columns={columns}
          rowKey="id"
          pagination={false}
          dataSource={dataResponse.items}
          scroll={{ x: 600 }}
          loading={loading}
        />
        {dataResponse.totalCount > LIMIT && (
          <Pagination
            showSizeChanger={false}
            defaultCurrent={LIMIT}
            current={filter.page}
            onChange={handleChangePage}
            showLessItems
            total={dataResponse.totalCount}
            pageSize={LIMIT}
          />
        )}
      </div>
      {isOpenModalEditAndCreate && (
        <ModalCreateEdit
          isOpen={isOpenModalEditAndCreate}
          handleCancelCreateEditModal={handleCancelCreateEditModal}
          valueUserSelected={valueUserSelected}
          onChangeStatusUpdateData={onChangeStatusUpdateData}
        />
      )}
    </div>
  );
};

export default DomesticManger;
